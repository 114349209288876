import React, { useState, useEffect } from "react";
import { usePrefences } from "../../context/PrefencesContext";
import { useConfig } from "../../context/ConfigContext";
import TreeMain from "../../components/Tree/TreeMain";
import Alert from "../../components/Alert";
import {
  ArrowRightCircleIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/solid";

import PaginationClassic from "../../components/PaginationClassic";
import TreeMainNav from "../../components/Tree/TreeMainNav";
import Requests from "../../helpers/Requests";
import {
  addDays,
  checkRole,
  diagnosticResponse,
  formatDate,
  getDate,
} from "../../helpers/Helpers";
import SortByItem from "../../components/SortByItems";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

import DropdownFilter from "../../components/DropdownFilter";
import CasinoTotals from "../../components/TotalsFooter/CasinoTotals";
import { useNavigate } from "react-router-dom";
import DropdownCasinoGame from "../../components/Search/DropdownCasinoGame";
import Dropdown from "../../components/Dropdown";

function CasinoGameReport() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "ReportCasinoGameReportsList")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [excel, setExcel] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const [formData, setFormData] = useState({
    date1: formatDate(getDate(), false),
    date2: formatDate(addDays(getDate(), 1), false),
    game: null,
    provider: [],
    rootProvider: "",
    page: 1,
    sort: 0,
    sortBy: 1,
  });

  const [data, setData] = useState([]);
  const [totals, setTotals] = useState([]);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = { ...formData };
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.excel = excel;
      Requests.postData("/report/getCasinoGameReport", postData, excel)
        .then((response) => {
          let res = response.data;
          if (excel) {
            const blob = new Blob([response.data], {
              type: "application/vnd.ms-excel",
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "export.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();
          } else {
            if (res.type !== "success") {
              diagnosticResponse(res);
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
              document.getElementById("mainBody").scrollTo(0, 0);
            } else {
              if (formData.page === 1) {
                setTotalItems(res.c);
                setTotals(res.totals);
              }
              setData(res.data);
            }
          }
          setLoading(false);
          setExcel(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
          setExcel(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);

  useEffect(() => {
    if (excel) {
      getItems();
    } // eslint-disable-next-line
  }, [excel]);

  const [filterData, setFilterData] = useState(null);
  useEffect(() => {
    getProviders();
  }, []);
  const getProviders = () => {
    Requests.postData("/report/getCasinoGameReportFilters", {})
      .then((response) => {
        let res = response.data;
        if (diagnosticResponse(res) && res.type === "success") {
          setFilterData(res.data);
          setAllProviders(res.data.providers);
        }
      })
      .catch(() => {});
  };

  const [allProviders, setAllProviders] = useState([]);
  useEffect(() => {
    if (filterData && Object.keys(filterData.providers).length > 0) {
      setAllProviders([]);
      setFormData((prev) => {
        return { ...prev, provider: [] };
      });
      setTimeout(() => {
        setAllProviders(filterData.providers);
      }, 500);
    }
    // eslint-disable-next-line
  }, [formData.rootProvider]);

  return ConfigProvider.config.user !== null ? (
    <>
      <div className="flex space-x-4">
        {ConfigProvider.config.isShopSystem &&
        (ConfigProvider.config.user.auth === "0") |
          (ConfigProvider.config.user.auth === "1") ? (
          <TreeMain
            callback={() => {
              getItems();
            }}
          />
        ) : null}
        <div className="relative grow min-w-0">
          {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (formData.page !== 1) {
                setFormData((prev) => {
                  return { ...prev, page: 1 };
                });
              } else {
                getItems();
              }
            }}
            className=""
          >
            {messages.length > 0 && (
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>
            )}
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5 w-full">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                {ConfigProvider.getTranslation("CasinoGameReport")} ✨
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                <button
                  type="submit"
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                  <span className="ml-2">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                </button>
                {checkRole(
                  ConfigProvider.config.user,
                  "ReportCasinoGameReportsExcel"
                ) && (
                  <button
                    type="button"
                    onClick={() => {
                      setExcel(true);
                    }}
                    className="btn bg-emerald-500 hover:bg-emerald-600 text-slate-800"
                  >
                    <DocumentTextIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                    <span className="ml-2">
                      {ConfigProvider.getTranslation("Excel")}
                    </span>
                  </button>
                )}
              </div>
            </div>
            {/* More actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5 w-full">
              {/* Left side */}
              <div className="mb-4 grow flex flex-col lg:flex-row lg:mb-0 gap-4 mr-2">
                <div className="sm:w-1/2">
                  {Object.keys(allProviders).length > 0 && (
                    <Dropdown
                      name="provider"
                      topItems={{
                        "": ConfigProvider.getTranslation("Undefined"),
                      }}
                      items={allProviders}
                      selected={formData.provider}
                      setSelected={(item) =>
                        setFormData((prev) => {
                          if (prev.provider.includes(item)) {
                            return {
                              ...prev,
                              provider: prev.provider.filter(
                                (val) => val !== item
                              ),
                            };
                          } else {
                            return {
                              ...prev,
                              provider: [...prev.provider, item],
                            };
                          }
                        })
                      }
                      multi={true}
                    />
                  )}
                </div>

                <div className="sm:w-1/2 flex min-w-0">
                  <DropdownCasinoGame
                    className="w-full"
                    undefinedItem={true}
                    setSelectedItem={(val) => {
                      setFormData((prev) => {
                        return {
                          ...prev,
                          game: val,
                        };
                      });
                    }}
                  />
                </div>
              </div>
              {/* Right side */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-end gap-2">
                <input
                  id="date1"
                  name="date1"
                  type="date"
                  value={formData.date1}
                  onChange={(e) => {
                    return setFormData({ ...formData, date1: e.target.value });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("StartDate")}
                  disabled={loading}
                  required
                />
                <input
                  id="date2"
                  name="date2"
                  type="date"
                  value={formData.date2}
                  onChange={(e) => {
                    return setFormData({ ...formData, date2: e.target.value });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("EndDate")}
                  disabled={loading}
                  required
                />
                {filterData &&
                  Object.keys(filterData.rootProviders).length > 0 && (
                    <DropdownFilter align="right">
                      <>
                        <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                          {ConfigProvider.getTranslation("Provider")}
                        </div>
                        <ul className="mb-4">
                          {Object.keys(filterData.rootProviders).map((item) => (
                            <li key={item} className="py-1 px-3">
                              <label className="flex items-center cursor-pointer">
                                <input
                                  id={item}
                                  name={item}
                                  type="checkbox"
                                  className="form-checkbox"
                                  checked={formData.rootProvider === item}
                                  onChange={() => {
                                    setFormData((prev) => {
                                      if (prev.rootProvider === item) {
                                        return {
                                          ...prev,
                                          rootProvider: "",
                                        };
                                      } else {
                                        return {
                                          ...prev,
                                          rootProvider: item,
                                        };
                                      }
                                    });
                                  }}
                                  disabled={loading}
                                />
                                <span className="text-sm font-medium ml-2">
                                  {filterData.rootProviders[item]}
                                </span>
                              </label>
                            </li>
                          ))}
                        </ul>
                      </>
                    </DropdownFilter>
                  )}
              </div>
            </div>
            {/* Table */}
            <CasinoGameReportTable
              data={data}
              loading={loading}
              formData={formData}
              setFormData={setFormData}
            />
            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                page={formData.page}
                setPage={(val) => {
                  setFormData((prev) => {
                    return { ...prev, page: val };
                  });
                }}
                totalItems={totalItems}
              />

              <div className="lg:flex gap-2">
                {totals.map((item, index) => (
                  <CasinoTotals key={index} item={item} />
                ))}
              </div>
            </div>
          </form>
        </div>
      </div>
      {ConfigProvider.config.isShopSystem &&
      (ConfigProvider.config.user.auth === "0") |
        (ConfigProvider.config.user.auth === "1") ? (
        <TreeMainNav
          callback={() => {
            getItems();
          }}
        />
      ) : null}
    </>
  ) : null;
}
function CasinoGameReportTable({ data, loading, formData, setFormData }) {
  const ConfigProvider = useConfig();
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("Reports")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Game")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Provider")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Bet")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={2}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Won")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={3}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("BonusWageringBet")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={4}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("BonusWageringWin")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={5}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("BonusAward")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={6}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Freespin")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={7}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Total")}
                    </div>
                  </SortByItem>
                </th>
              </tr>
            </thead>

            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              {!loading && data && data.length === 0 && (
                <tr>
                  <td colSpan="9">
                    <NoData />
                  </td>
                </tr>
              )}
              {loading ? (
                <tr>
                  <td colSpan="9" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              ) : (
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-left">{`${item.displayName}`}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-center">{`${item.provider}`}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-center text-purple-500">{`${item.amountNegative} ${item.currency}`}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-center text-sky-500">{`${item.amount} ${item.currency}`}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-center text-slate-500">{`${item.bonusWageringBet} ${item.currency}`}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-center text-slate-500">{`${item.bonusWageringWin} ${item.currency}`}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-center text-teal-500">{`${item.bonusAward} ${item.currency}`}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="text-center text-indigo-500">{`${item.freespinWin} ${item.currency}`}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div
                          className={`text-center ${
                            item.t2 > 0
                              ? "text-emerald-500"
                              : item.t2 < 0
                              ? "text-rose-500"
                              : ""
                          }`}
                        >{`${item.total} ${item.currency}`}</div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default CasinoGameReport;

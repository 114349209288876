import React, { useEffect, useState } from "react";
import TreeMainNav from "../components/Tree/TreeMainNav";
import PaginationClassic from "../components/PaginationClassic";
import { useConfig } from "../context/ConfigContext";
import Alert from "../components/Alert";
import SearchFilter from "../components/SearchFilter";
import {
  ArrowRightCircleIcon,
  ArrowUturnLeftIcon,
  BuildingLibraryIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import DropdownCard from "../components/Search/DropdownCard";
import {
  formatDate,
  getDate,
  addDays,
  classNames,
  diagnosticResponse,
  checkRole,
} from "../helpers/Helpers";
import TreeMain from "../components/Tree/TreeMain";
import DropdownFilter from "../components/DropdownFilter";
import DropdownEvent from "../components/Search/DropdownEvent";
import { usePrefences } from "../context/PrefencesContext";
import Requests from "../helpers/Requests";
import SortByItem from "../components/SortByItems";
import LoadingSpinner from "../components/Loading/LoadingSpinner";
import {
  ArchiveBoxIcon,
  CalendarIcon,
  CheckIcon,
  PaperAirplaneIcon,
  PrinterIcon,
} from "@heroicons/react/24/outline";
import ShopTooltip from "../components/ShopTooltip";
import NoData from "../components/NoData";
import { Link, useNavigate, useParams } from "react-router-dom";
import Confirm from "../components/Confirm";
import { useToastr } from "../context/ToastrContext";
import ClassicTotals from "../components/TotalsFooter/ClassicTotals";

function Tickets({ selCard = undefined, selAdmin = undefined }) {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  const { cid } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "SportBetList")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState(cid ?? "");

  const [formData, setFormData] = useState({
    date1: formatDate(addDays(getDate(), -5), true, false),
    date2: formatDate(addDays(getDate(), 1), true, false),
    card: selCard ? selCard : null,
    page: 1,
    sort: 0,
    sortBy: 1,
    status: "-1",
    isLive: "-1",
    payment: "-1",
    type: "-1",
    eventSearch: null,
    risk: "-1",
    account: selAdmin ? selAdmin : null,
  });

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(null);
  const [totals, setTotals] = useState([]);
  const [showTotals, setShowTotals] = useState(false);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = { ...formData };
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.search = search;
      postData.total = false;
      Requests.postData("/bets/getBets", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotal(null);
              setTotals([]);
              setShowTotals(false);
              setTotalItems(res.c);
            }
            res.data.forEach((item) => {
              item.visited = item.visited || false;
              item.return =
                !item.isC &&
                !item.isSold &&
                "3" !== item.stat &&
                "0" === ConfigProvider.config.user.auth
                  ? 1
                  : item.isC &&
                    !item.isSold &&
                    "3" !== item.stat &&
                    "0" === ConfigProvider.config.user.auth
                  ? 2
                  : 0;
            });
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);

  const allStatus = {
    "-1": ConfigProvider.getTranslation("All"),
    0: ConfigProvider.getTranslation("Pending"),
    1: ConfigProvider.getTranslation("Won"),
    2: ConfigProvider.getTranslation("Lost"),
    3: ConfigProvider.getTranslation("Return"),
    4: ConfigProvider.getTranslation("Sold"),
  };
  const allPaymentStatus = {
    "-1": ConfigProvider.getTranslation("All"),
    0: ConfigProvider.getTranslation("Unpaid"),
    1: ConfigProvider.getTranslation("Paid"),
  };
  const allIsLive = {
    "-1": ConfigProvider.getTranslation("All"),
    0: ConfigProvider.getTranslation("PreGame"),
    1: ConfigProvider.getTranslation("Live"),
  };
  const allType = {
    "-1": ConfigProvider.getTranslation("All"),
    0: ConfigProvider.getTranslation("BetSlip.Single"),
    1: ConfigProvider.getTranslation("BetSlip.Kombination"),
    2: ConfigProvider.getTranslation("BetSlip.Multiple"),
    3: ConfigProvider.getTranslation("BetSlip.System"),
  };
  const allRisk = {
    "-1": ConfigProvider.getTranslation("All"),
    0: ConfigProvider.getTranslation("RiskyBets"),
  };

  const [totalsLoading, setTotalsLoading] = useState(false);
  const getTotals = () => {
    if (!loading && !totalsLoading) {
      setTotalsLoading(true);
      const postData = { ...formData };
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.search = search;
      postData.total = true;
      Requests.postData("/bets/getBets", postData)
        .then((response) => {
          let res = response.data;
          if (diagnosticResponse(res) && res.type === "success") {
            setTotal({
              amount: res.amount,
              won: res.won,
              net: res.net,
              currency: res.currency,
            });
            setTotals(res.totals);
          }
          setTotalsLoading(false);
        })
        .catch(() => {
          setTotalsLoading(false);
        });
    }
  };

  return ConfigProvider.config.user !== null ? (
    <>
      <div className="flex space-x-4">
        {ConfigProvider.config.isShopSystem &&
        !selCard &&
        !selAdmin &&
        (ConfigProvider.config.user.auth === "0") |
          (ConfigProvider.config.user.auth === "1") ? (
          <TreeMain
            callback={() => {
              getItems();
            }}
          />
        ) : null}
        <div className="relative grow min-w-0">
          {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (formData.page !== 1) {
                setFormData((prev) => {
                  return { ...prev, page: 1 };
                });
              } else {
                getItems();
              }
            }}
            className=""
          >
            {messages.length > 0 && (
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>
            )}
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                  {ConfigProvider.getTranslation("Tickets")} ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Search form */}
                <SearchFilter
                  placeholder={`${ConfigProvider.getTranslation(
                    "BetId"
                  )}/${ConfigProvider.getTranslation("Note")}`}
                  search={search}
                  setSearch={setSearch}
                />
                <button
                  type="submit"
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                  <span className="ml-2">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                </button>
              </div>
            </div>
            {/* More actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left side */}
              <div className="mb-4 grow flex flex-col lg:flex-row lg:mb-0 gap-4 mr-2">
                {!selCard && (
                  <DropdownCard
                    setSelectedItem={(val) => {
                      setFormData((prev) => {
                        return {
                          ...prev,
                          card: val,
                        };
                      });
                    }}
                  />
                )}
                <DropdownEvent
                  setSelectedItem={(val) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        eventSearch: val,
                      };
                    });
                  }}
                />
              </div>
              {/* Right side */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-end gap-2">
                <input
                  id="date1"
                  name="date1"
                  type="datetime-local"
                  value={formData.date1}
                  onChange={(e) => {
                    return setFormData({ ...formData, date1: e.target.value });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("StartDate")}
                  disabled={loading}
                  required
                />
                <input
                  id="date2"
                  name="date2"
                  type="datetime-local"
                  value={formData.date2}
                  onChange={(e) => {
                    return setFormData({ ...formData, date2: e.target.value });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("EndDate")}
                  disabled={loading}
                  required
                />
                <DropdownFilter align="right">
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("Status")}
                  </div>
                  <ul className="mb-4">
                    {Object.keys(allStatus).map((item) => (
                      <li key={item} className="py-1 px-3">
                        <label className="flex items-center cursor-pointer">
                          <input
                            id={item}
                            name={item}
                            type="checkbox"
                            className="form-checkbox"
                            checked={formData.status === item}
                            onChange={() =>
                              setFormData({
                                ...formData,
                                status: item,
                              })
                            }
                            disabled={loading}
                          />
                          <span className="text-sm font-medium ml-2">
                            {allStatus[item]}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.config.payPlatform.includes("bo")
                      ? ConfigProvider.getTranslation("Payment")
                      : ""}
                  </div>
                  <ul className="mb-4">
                    {Object.keys(allPaymentStatus).map((item) => (
                      <li key={item} className="py-1 px-3">
                        <label className="flex items-center cursor-pointer">
                          <input
                            id={item}
                            name={item}
                            type="checkbox"
                            className="form-checkbox"
                            checked={formData.payment === item}
                            onChange={() =>
                              setFormData({
                                ...formData,
                                payment: item,
                              })
                            }
                            disabled={loading}
                          />
                          <span className="text-sm font-medium ml-2">
                            {allPaymentStatus[item]}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("IsLive")}
                  </div>
                  <ul className="mb-4">
                    {Object.keys(allIsLive).map((item) => (
                      <li key={item} className="py-1 px-3">
                        <label className="flex items-center cursor-pointer">
                          <input
                            id={item}
                            name={item}
                            type="checkbox"
                            className="form-checkbox"
                            checked={formData.isLive === item}
                            onChange={() =>
                              setFormData({
                                ...formData,
                                isLive: item,
                              })
                            }
                            disabled={loading}
                          />
                          <span className="text-sm font-medium ml-2">
                            {allIsLive[item]}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("Type")}
                  </div>
                  <ul className="mb-4">
                    {Object.keys(allType).map((item) => (
                      <li key={item} className="py-1 px-3">
                        <label className="flex items-center cursor-pointer">
                          <input
                            id={item}
                            name={item}
                            type="checkbox"
                            className="form-checkbox"
                            checked={formData.type === item}
                            onChange={() =>
                              setFormData({
                                ...formData,
                                type: item,
                              })
                            }
                            disabled={loading}
                          />
                          <span className="text-sm font-medium ml-2">
                            {allType[item]}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                  {checkRole(
                    ConfigProvider.config.user,
                    "SportBetListRiskybets"
                  ) && (
                    <>
                      <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                        {ConfigProvider.getTranslation("Filter")}
                      </div>
                      <ul className="mb-4">
                        {Object.keys(allRisk).map((item) => (
                          <li key={item} className="py-1 px-3">
                            <label className="flex items-center cursor-pointer">
                              <input
                                id={item}
                                name={item}
                                type="checkbox"
                                className="form-checkbox"
                                checked={formData.risk === item}
                                onChange={() =>
                                  setFormData({
                                    ...formData,
                                    risk: item,
                                  })
                                }
                                disabled={loading}
                              />
                              <span className="text-sm font-medium ml-2">
                                {allRisk[item]}
                              </span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </DropdownFilter>
              </div>
            </div>

            {/* Table */}
            <TicketsTable
              data={data}
              loading={loading}
              formData={formData}
              setFormData={setFormData}
              getItems={getItems}
              selCard={selCard}
            />

            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                page={formData.page}
                setPage={(val) => {
                  setFormData((prev) => {
                    return { ...prev, page: val };
                  });
                }}
                totalItems={totalItems}
              />
              <div className="my-2">
                {!showTotals && (
                  <button
                    type="button"
                    onClick={() => {
                      setShowTotals(!showTotals);
                      if (!total) {
                        getTotals();
                      }
                    }}
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                  >
                    {ConfigProvider.getTranslation("Totals")}
                  </button>
                )}
                {showTotals && total && (
                  <div className="lg:flex gap-2 pb-2 mb-2 border-b border-slate-200 dark:border-slate-600">
                    <ClassicTotals item={total} />
                  </div>
                )}
                {showTotals && (
                  <div className="lg:flex gap-2">
                    {totals.map((item, index) => (
                      <ClassicTotals key={index} item={item} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      {ConfigProvider.config.isShopSystem &&
      (ConfigProvider.config.user.auth === "0") |
        (ConfigProvider.config.user.auth === "1") ? (
        <TreeMainNav
          callback={() => {
            getItems();
          }}
        />
      ) : null}
    </>
  ) : null;
}
function TicketsTable({
  data,
  loading,
  formData,
  setFormData,
  getItems,
  selCard,
}) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const returnBet = (item) => {
    if (!showConfirm) {
      setConfirmItem(item);
      setShowConfirm(true);
    }
  };
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmItem, setConfirmItem] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const updateBet = () => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/bets/returnCoupon", { _id: confirmItem._id })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });

            setShowConfirm(false);
            setConfirmItem(null);
            setTimeout(() => {
              getItems();
            }, 100);
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
          setShowConfirm(false);
          setConfirmItem(null);
        });
    }
  };
  const [showPayConfirm, setShowPayConfirm] = useState(false);
  const payConfirm = (item) => {
    if (!showPayConfirm) {
      setConfirmItem(item);
      setShowPayConfirm(true);
    }
  };
  const payBet = () => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/bets/payBet", { _id: confirmItem._id })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setShowPayConfirm(false);
            if (res.data === "-") {
              setPinValue("");
              setShowPayPINConfirm(true);
            } else {
              openPrintWindow(res.data);
            }
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
          setShowPayConfirm(false);
          setConfirmItem(null);
        });
    }
  };
  const [showPayPINConfirm, setShowPayPINConfirm] = useState(false);
  const [pinValue, setPinValue] = useState("");
  const openPrintWindow = (pin = "") => {
    if (confirmItem !== null) {
      window.open(
        `${process.env.REACT_APP_CDN_ENDPOINT}/payBet?id=${confirmItem._id}&pin=${pin}`,
        "",
        "menubar=no,location=no,status=no,resizable=yes,scrollbars=no"
      );
      setConfirmItem(null);
      setShowPayPINConfirm(false);
      setTimeout(() => {
        getItems();
      }, 100);
    }
  };
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("Tickets")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={0}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Id")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Card")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={2}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Date")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={3}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Amount")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={4}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Rate")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={5}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("PossibleWinnings")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={6}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Won")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="font-semibold text-center">
                    {ConfigProvider.getTranslation("Type")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="font-semibold text-center">
                    {ConfigProvider.getTranslation("Status")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="font-semibold text-center">
                    {ConfigProvider.getTranslation("Payment")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="font-semibold text-center">
                    {ConfigProvider.getTranslation("Bet")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  &nbsp;
                </th>
                <th className="first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  &nbsp;
                </th>
              </tr>
            </thead>
            {!loading && data && data.length === 0 && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                <tr>
                  <td colSpan="13">
                    <NoData />
                  </td>
                </tr>
              </tbody>
            )}
            {loading && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                <tr>
                  <td colSpan="13" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading &&
              data.map((item) => (
                <TicketsTableItem
                  key={item._id}
                  item={item}
                  returnBet={returnBet}
                  selCard={selCard}
                  payConfirm={payConfirm}
                />
              ))}
          </table>
        </div>
      </div>

      {showConfirm && confirmItem !== null && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{`${ConfigProvider.getTranslation("BetId")}: ${
                confirmItem.id
              }`}</p>
              <p>{`${ConfigProvider.getTranslation("Amount")}: ${
                confirmItem.amount
              } ${confirmItem.currency}`}</p>
              <p>{`${ConfigProvider.getTranslation("Rate")}: ${parseFloat(
                confirmItem.rate
              ).toFixed(2)}`}</p>
              <p>{`${ConfigProvider.getTranslation(
                "PossibleWinnings"
              )}: ${parseFloat(confirmItem.possiblegain).toFixed(2)}`}</p>
            </>
          }
          confirm={() => {
            updateBet();
          }}
          callback={() => {
            setShowConfirm(false);
            setConfirmItem(null);
          }}
        />
      )}
      {showPayConfirm && confirmItem !== null && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{`${ConfigProvider.getTranslation("BetId")}: ${
                confirmItem.id
              }`}</p>
              <p>{`${ConfigProvider.getTranslation("Amount")}: ${
                confirmItem.amount
              } ${confirmItem.currency}`}</p>
              <p>{`${ConfigProvider.getTranslation("Rate")}: ${parseFloat(
                confirmItem.rate
              ).toFixed(2)}`}</p>
              <p>{`${ConfigProvider.getTranslation(
                "PossibleWinnings"
              )}: ${parseFloat(confirmItem.possiblegain).toFixed(2)}`}</p>
            </>
          }
          confirm={() => {
            payBet();
          }}
          callback={() => {
            setShowPayConfirm(false);
          }}
        />
      )}
      {showPayPINConfirm && confirmItem !== null && (
        <Confirm
          title={ConfigProvider.getTranslation("PIN")}
          text={
            <>
              <p>{ConfigProvider.getTranslation("PIN")}:</p>
              <p>
                <input
                  id="pinValue"
                  name="pinValue"
                  type="number"
                  value={pinValue}
                  onChange={(e) => {
                    setPinValue(e.target.value);
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("PIN")}
                  maxLength={6}
                  disabled={confirmLoading}
                  step={1}
                  pattern="\d*"
                />
              </p>
            </>
          }
          confirm={() => {
            openPrintWindow(pinValue);
          }}
          callback={() => {
            setShowPayPINConfirm(false);
            setConfirmItem(null);
          }}
        />
      )}
    </div>
  );
}
function TicketsTableItem({ item, returnBet, selCard, payConfirm }) {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  const [descriptionOpen, setDescriptionOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedBet, setSelectedBet] = useState(null);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/bets/getBetDetail", { cid: item._id })
        .then((response) => {
          let res = response.data;
          diagnosticResponse(res) &&
            res.type === "success" &&
            setData(res.data.cm);
          setSelectedBet(res.data.c);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (descriptionOpen && data.length === 0) {
      getItems();
    }
    // eslint-disable-next-line
  }, [descriptionOpen]);

  return (
    <tbody className="text-sm">
      <tr
        className={`${
          item.isC
            ? "bg-sky-100/50 dark:bg-sky-900/50"
            : item.isSold | (item.stat === "1")
            ? "bg-emerald-100/50 dark:bg-emerald-900/50"
            : item.stat === "2"
            ? "bg-rose-100/50 dark:bg-rose-900/50"
            : ""
        }`}
      >
        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
          <div className="font-medium text-slate-800 dark:text-slate-100 text-left">
            <Link
              onClick={() => setDescriptionOpen(!descriptionOpen)}
              className="text-purple-500 dark:text-purple-400"
            >
              {item.id}
            </Link>
          </div>
        </td>
        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
          {!selCard ? (
            <Link
              to={"/cards/card/view/" + item.aid}
              className="text-sky-500 dark:text-sky-400 font-medium text-left"
            >
              {item.uname}
            </Link>
          ) : (
            <div className="text-slate-800 dark:text-slate-100 font-medium text-left">
              {item.uname}
            </div>
          )}
        </td>
        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
          <div className="flex items-center justify-center w-full">
            <CalendarIcon className="w-4 h-4 mr-1" />
            {new Intl.DateTimeFormat("default", {
              day: "numeric",
              month: "numeric",
              hour: "numeric",
              minute: "numeric",
              timeZone: PrefencesProvider.prefences.timezone,
            }).format(new Date(item.date))}
          </div>
        </td>
        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{`${item.amount} ${item.currency}`}</div>
        </td>
        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{`${parseFloat(item.rate).toFixed(
            2
          )}`}</div>
        </td>
        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{`${parseFloat(
            item.possiblegain
          ).toFixed(2)}`}</div>
        </td>
        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
          <div
            className={classNames(
              "text-center",
              item.won > 0 ? "text-emerald-500" : "text-slate-500"
            )}
          >{`${parseFloat(item.won).toFixed(2)}`}</div>
        </td>
        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">
            {item.type === "0"
              ? ConfigProvider.getTranslation("BetSlip.Single")
              : item.type === "1"
              ? ConfigProvider.getTranslation("BetSlip.Kombination")
              : item.type === "2"
              ? ConfigProvider.getTranslation("BetSlip.Multiple")
              : item.type === "3"
              ? `${ConfigProvider.getTranslation("BetSlip.System")} (${
                  item.selCombination
                })`
              : ""}
          </div>
        </td>
        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
          <div
            className={classNames(
              "text-center font-semibold",
              item.isC
                ? "text-sky-500"
                : item.isSold | (item.stat === "1")
                ? "text-emerald-500"
                : item.stat === "2"
                ? "text-rose-500"
                : item.stat === "0"
                ? "text-slate-400 dark:text-slate-500"
                : "text-sky-500"
            )}
          >
            {item.isC
              ? ConfigProvider.getTranslation("Cancelled")
              : item.isSold
              ? ConfigProvider.getTranslation("Sold")
              : item.stat === "0"
              ? ConfigProvider.getTranslation("Open")
              : item.stat === "1"
              ? ConfigProvider.getTranslation("Won")
              : item.stat === "2"
              ? ConfigProvider.getTranslation("Lost")
              : ConfigProvider.getTranslation("Cancel")}
          </div>
        </td>
        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
          {ConfigProvider.config.payPlatform.includes("bo") &&
          (item.stat === "1") | item.isSold ? (
            <div
              className={classNames(
                "text-center flex items-center justify-center font-semibold",
                item.isPaid ? "text-emerald-500" : "text-rose-500"
              )}
            >
              {item.isPaid ? (
                <div className="border rounded-full border-emerald-500 p-1">
                  <CheckIcon className="w-4 h-4 " />
                </div>
              ) : (
                <div className="border rounded-full border-rose-500 p-1">
                  <XMarkIcon className="w-4 h-4 " />
                </div>
              )}
            </div>
          ) : null}
        </td>
        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
          <div className="text-center">{`${item.nTotal} / ${item.nWins}`}</div>
        </td>
        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
          {ConfigProvider.config.isShopSystem && (
            <ShopTooltip
              size="lg"
              position="left"
              bg="dark"
              shopName=""
              shop={item.shop}
              auth="2"
            />
          )}
        </td>
        <td className="first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <button
              type="button"
              className={`text-slate-400 hover:text-slate-500 dark:text-slate-500 dark:hover:text-slate-400 ${
                descriptionOpen && "rotate-180"
              }`}
              aria-expanded={descriptionOpen}
              onClick={() => setDescriptionOpen(!descriptionOpen)}
              aria-controls={`description-${item.id}`}
            >
              <span className="sr-only">Menu</span>
              <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
              </svg>
            </button>
          </div>
        </td>
      </tr>
      {descriptionOpen && (
        <tr
          id={`description-${item.id}`}
          role="region"
          className={`${!descriptionOpen && "hidden"}`}
        >
          <td
            colSpan="13"
            className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3"
          >
            <div className="flex flex-col items-center bg-slate-50 dark:bg-slate-900/30 dark:text-slate-400 p-3 -mt-3">
              <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
                  <tr>
                    <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                      <div className="grow font-semibold text-left">
                        {ConfigProvider.getTranslation("Event")}
                      </div>
                    </th>

                    <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                      <div className="grow font-semibold text-center">
                        {ConfigProvider.getTranslation("Tip")}
                      </div>
                    </th>
                    <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                      <div className="grow font-semibold text-center">
                        {ConfigProvider.getTranslation("Selection")}
                      </div>
                    </th>
                    <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                      <div className="grow font-semibold text-center">
                        {ConfigProvider.getTranslation("Rate")}
                      </div>
                    </th>
                    <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                      <div className="grow font-semibold text-center">
                        {ConfigProvider.getTranslation("Status")}
                      </div>
                    </th>
                    <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                      <div className="grow font-semibold text-center">
                        {ConfigProvider.getTranslation("IsLive")}
                      </div>
                    </th>
                    <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                      <div className="grow font-semibold text-center">
                        {ConfigProvider.getTranslation("Period")}
                      </div>
                    </th>
                  </tr>
                </thead>
                {!loading && data && data.length === 0 && (
                  <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                    <tr>
                      <td colSpan="8">
                        <NoData />
                      </td>
                    </tr>
                  </tbody>
                )}
                {loading && (
                  <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                    <tr>
                      <td colSpan="8" className="text-center py-10">
                        <LoadingSpinner className="w-12 h-12" />
                      </td>
                    </tr>
                  </tbody>
                )}
                {!loading && (
                  <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                    {data.map((item) => (
                      <tr key={item.cm._id}>
                        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                          <div className="font-medium text-slate-800 dark:text-slate-100 flex flex-col">
                            <div className="flex items-center text-xs text-slate-700 dark:text-slate-500">
                              <CalendarIcon className="w-4 h-4 mr-1" />
                              {new Intl.DateTimeFormat("default", {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                timeZone: PrefencesProvider.prefences.timezone,
                              }).format(new Date(item.e.eventdate))}
                            </div>
                            <div>{item.e.label}</div>
                            <div className="text-xs text-slate-700 dark:text-slate-500">
                              {`${item.country.name} / ${item.league.name}`}
                            </div>
                          </div>
                        </td>

                        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-center">{item.market}</div>
                        </td>
                        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                          <div className="flex items-center justify-center w-full">
                            {item.selection}
                            {item.cm.isBanko && (
                              <BuildingLibraryIcon className="ml-1 w-4 h-4 text-slate-700 dark:text-slate-500" />
                            )}
                          </div>
                        </td>

                        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                          <div className="text-center">
                            {parseFloat(item.cm.odd).toFixed(2)}
                          </div>
                        </td>
                        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                          <div
                            className={classNames(
                              "text-center font-semibold",
                              item.cm.status === "0"
                                ? "text-slate-400 dark:text-slate-500"
                                : item.cm.status === "1"
                                ? "text-emerald-500"
                                : item.cm.status === "2"
                                ? "text-rose-500"
                                : item.cm.status === "3"
                                ? "text-sky-500"
                                : ""
                            )}
                          >
                            {item.cm.status === "0"
                              ? ConfigProvider.getTranslation("Open")
                              : item.cm.status === "1"
                              ? ConfigProvider.getTranslation("Won")
                              : item.cm.status === "2"
                              ? ConfigProvider.getTranslation("Lost")
                              : item.cm.status === "3"
                              ? ConfigProvider.getTranslation("Cancel")
                              : item.cm.status}
                          </div>
                        </td>
                        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                          <div
                            className={`text-center ${
                              item.cm.isLive ? "text-emerald-500" : ""
                            }`}
                          >
                            {item.cm.isLive
                              ? ConfigProvider.getTranslation("Yes")
                              : ConfigProvider.getTranslation("No")}
                          </div>
                        </td>
                        <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                          <div className="flex w-full flex-col text-xs">
                            <div className="text-center text-slate-400 dark:text-slate-500">
                              {item.p}
                              {item.cm.timer && item.cm.timer.length > 0
                                ? " - " + item.cm.timer
                                : ""}
                            </div>
                            <div className="text-center">({item.cm.score})</div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>

              <div className="w-full flex border-t border-slate-200 dark:border-slate-700 gap-2 justify-between">
                <div className="grid grid-cols-2 px-3 py-2">
                  <span className="text-left">Ip:</span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://ipinfo.io/${item.ip}`}
                    className="font-medium text-indigo-500"
                  >
                    {item.ip}
                  </a>
                  {selectedBet && selectedBet.note.length > 0 ? (
                    <>
                      <span className="text-left mr-2">
                        {ConfigProvider.getTranslation("Note")}:
                      </span>
                      <span>{selectedBet.note}</span>
                    </>
                  ) : null}
                </div>
                <div className="px-3 py-2 flex items-center justify-center space-x-2">
                  {checkRole(ConfigProvider.config.user, "SportBetReturn") &&
                    (item.return === 1) | (item.return === 2) && (
                      <Link
                        onClick={() => {
                          returnBet(item);
                        }}
                        className={`font-medium block px-2 py-1 rounded text-xs ${
                          item.return === 1
                            ? "bg-rose-500 text-white"
                            : item.return === 2
                            ? "bg-sky-500 text-slate-100"
                            : ""
                        }`}
                      >
                        {item.return === 1 && (
                          <div className="flex items-center">
                            <XMarkIcon className="w-4 h-4 mr-1 opacity-50" />
                            {ConfigProvider.getTranslation("Cancel")}
                          </div>
                        )}
                        {item.return === 2 && (
                          <div className="flex items-center">
                            <ArrowUturnLeftIcon className="w-4 h-4 mr-1 opacity-50" />
                            {ConfigProvider.getTranslation("ReOpen")}
                          </div>
                        )}
                      </Link>
                    )}
                  {checkRole(ConfigProvider.config.user, "SportBetPay") &&
                  ConfigProvider.config.payPlatform.includes("bo") &&
                  !item.isC &&
                  (item.stat === "1") | item.isSold ? (
                    <Link
                      onClick={() => {
                        payConfirm(item);
                      }}
                      className={`font-medium block px-2 py-1 rounded text-xs bg-indigo-500 text-slate-100`}
                    >
                      {!item.isPaid ? (
                        <div className="flex items-center">
                          <PaperAirplaneIcon className="w-4 h-4 mr-1 opacity-50" />
                          {ConfigProvider.getTranslation("Pay")}
                        </div>
                      ) : (
                        <div className="flex items-center">
                          <PrinterIcon className="w-4 h-4 mr-1 opacity-50" />
                          {ConfigProvider.getTranslation(
                            "PrintCopyPaymentReceipt"
                          )}
                        </div>
                      )}
                    </Link>
                  ) : null}
                  {checkRole(ConfigProvider.config.user, "ViewLogCoupon") && (
                    <Link
                      to={`/updateLogs/coupon/${item._id}`}
                      target="_blank"
                      title="Logs"
                      className={`font-medium block px-2 py-1 rounded text-xs bg-teal-500 text-white`}
                    >
                      <ArchiveBoxIcon className="w-4 h-4" />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
}
export default Tickets;

import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import { WelcomeBanner } from "../../components/WelcomeBanner";
import Alert from "../../components/Alert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import {
  ArrowRightCircleIcon,
  EyeIcon,
  EyeSlashIcon,
  EllipsisHorizontalCircleIcon,
  ArchiveBoxIcon,
} from "@heroicons/react/24/outline";
import { useToastr } from "../../context/ToastrContext";
import Confirm from "../../components/Confirm";
import Dropdown from "../../components/Dropdown";

function UpsertAccount() {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const { _id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "SiteManageUsers")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    uname: "",
    password: "",
    password2: "",
    name: "",
    sname: "",
    stat: 1,
    role: null,
    _id: _id ? _id : null,
  });
  const [messages, setMessages] = useState([]);
  const submitForm = (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      setMessages([]);
      let requiredFieldErros = [];
      if (formData.role === null) {
        requiredFieldErros.push(ConfigProvider.getTranslation("Role"));
      }

      if (requiredFieldErros.length > 0) {
        setMessages([
          {
            type: "error",
            text:
              "[" +
              requiredFieldErros.join(",") +
              "] " +
              ConfigProvider.getTranslation("Forms.EmptyValueError"),
          },
        ]);
        document.getElementById("mainBody").scrollTo(0, 0);
      } else {
        Requests.postData("/administration/updateAdminAccount", formData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              diagnosticResponse(res);
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
              document.getElementById("mainBody").scrollTo(0, 0);
            } else {
              setMessages([
                {
                  type: "success",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.SuccessMessage"),
                },
              ]);
              setTimeout(() => {
                navigate(`/system/accounts`);
              }, 1000);
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  useEffect(() => {
    if (formData._id) {
      Requests.postData("/administration/getAdminAccount", {
        _id: formData._id,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setFormData((prev) => {
              return {
                ...prev,
                name: res.data.name,
                role: res.data.role,
                sname: res.data.sname,
                stat: res.data.stat,
                uname: res.data.uname,
              };
            });
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [formData._id]);
  const [showConfirm, setShowConfirm] = useState(false);
  const resetGA = () => {
    if (formData._id && !loading) {
      setLoading(true);
      Requests.postData("/administration/resetGA", {
        _id: formData._id,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });

            setShowConfirm(false);
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setLoading(false);
          setShowConfirm(false);
        });
    }
  };
  const [allRules, setAllRules] = useState([]);
  const [roles, setRoles] = useState({});
  useEffect(() => {
    if (allRules.length === 0) {
      Requests.postData("/administration/getUserRolesSelect", {})
        .then((response) => {
          let res = response.data;
          diagnosticResponse(res) &&
            res.type === "success" &&
            setAllRules(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (allRules.length > 0) {
      let send = {};
      allRules
        .filter((x) => x.auth === "0")
        .forEach((item) => {
          send[item._id] = item.name;
        });

      setRoles(send);
    }
    // eslint-disable-next-line
  }, [formData.role, allRules]);
  const [showPassword, setShowPassword] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  return (
    <div className="grow max-w-3xl mx-auto lg:mt-20">
      <WelcomeBanner>
        {ConfigProvider.getTranslation("Accounts")}
        {" / "}
        {formData._id
          ? ConfigProvider.getTranslation("Edit")
          : ConfigProvider.getTranslation("Add")}
        💫
      </WelcomeBanner>
      <form className="w-full" autoComplete="off" onSubmit={submitForm}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
            {ConfigProvider.getTranslation("General")}
          </h2>
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
          <section>
            <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
              <div className="relative sm:col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="uname"
                >
                  {ConfigProvider.getTranslation("Username")}
                </label>
                <input
                  id="uname"
                  name="uname"
                  type="text"
                  value={formData.uname}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("Username")}
                  required
                  maxLength={15}
                  disabled={loading}
                />
              </div>
              {formData._id === null && (
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="password"
                  >
                    {ConfigProvider.getTranslation("Password.New")}
                  </label>
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className="w-full form-input"
                    placeholder="*******"
                    value={formData.password}
                    onChange={handleChange}
                    required={formData._id === null}
                    maxLength={15}
                    disabled={loading}
                  />
                  <div
                    className="inline-flex items-center justify-center absolute right-0 top-3 h-full w-10 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!showPassword ? (
                      <EyeIcon className="w-4 h-4 text-color-3" />
                    ) : (
                      <EyeSlashIcon className="w-4 h-4 text-color-3" />
                    )}
                  </div>
                </div>
              )}
              {formData._id === null && (
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="password2"
                  >
                    {ConfigProvider.getTranslation("Password.NewAgain")}
                  </label>
                  <input
                    id="password2"
                    name="password2"
                    type={showPassword ? "text" : "password"}
                    className="w-full form-input"
                    placeholder="*******"
                    value={formData.password2}
                    onChange={handleChange}
                    required={formData._id === null}
                    maxLength={15}
                    disabled={loading}
                  />
                  <div
                    className="inline-flex items-center justify-center absolute right-0 top-3 h-full w-10 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {!showPassword ? (
                      <EyeIcon className="w-4 h-4 text-color-3" />
                    ) : (
                      <EyeSlashIcon className="w-4 h-4 text-color-3" />
                    )}
                  </div>
                </div>
              )}

              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="name"
                >
                  {ConfigProvider.getTranslation("Name")}
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("Name")}
                  maxLength={50}
                  disabled={loading}
                />
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="sname"
                >
                  {ConfigProvider.getTranslation("Surname")}
                </label>
                <input
                  id="sname"
                  name="sname"
                  type="text"
                  value={formData.sname}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("Surname")}
                  maxLength={50}
                  disabled={loading}
                />
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="role"
                >
                  {ConfigProvider.getTranslation("Role")}
                </label>
                {Object.keys(roles).length && (
                  <Dropdown
                    name="role"
                    items={roles}
                    selected={formData.role}
                    setSelected={(val) => {
                      setFormData({ ...formData, role: val });
                    }}
                  />
                )}
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="stat"
                >
                  {ConfigProvider.getTranslation("Status")}
                </label>
                <div className="m-3 w-24">
                  <div className="flex items-center">
                    <div className="form-switch">
                      <input
                        id="stat"
                        name="stat"
                        type="checkbox"
                        className="sr-only"
                        checked={formData.stat === 1}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            stat: formData.stat === 1 ? 0 : 1,
                          })
                        }
                        disabled={loading}
                      />
                      <label
                        className="bg-slate-400 dark:bg-slate-700"
                        htmlFor="stat"
                      >
                        <span
                          className="bg-white shadow-sm"
                          aria-hidden="true"
                        ></span>
                      </label>
                    </div>
                    <div className="text-sm text-slate-700 dark:text-slate-400  ml-2">
                      {formData.stat === 1
                        ? ConfigProvider.getTranslation("Active")
                        : ConfigProvider.getTranslation("Passive")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {formData._id !== null && (
            <section>
              <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">
                {ConfigProvider.getTranslation("Password")}
              </h2>

              <div className="text-sm">
                {ConfigProvider.getTranslation(
                  "PermanentPasswordIfYouDontWantToUseTemporaryLoginCodes"
                )}
              </div>

              <div className="mt-5">
                {!editPassword && (
                  <button
                    type="button"
                    className="btn border-slate-200 dark:border-slate-700 shadow-sm text-indigo-500"
                    onClick={() => {
                      setEditPassword(true);
                      setFormData({ ...formData, password: "", password2: "" });
                    }}
                  >
                    {ConfigProvider.getTranslation("ChangePassword")}
                  </button>
                )}
                {editPassword && (
                  <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password"
                      >
                        {ConfigProvider.getTranslation("Password.New")}
                      </label>
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        className="w-full form-input"
                        placeholder="*******"
                        value={formData.password}
                        onChange={handleChange}
                        required
                        maxLength={15}
                        disabled={loading}
                      />
                      <div
                        className="inline-flex items-center justify-center absolute right-0 top-3 h-full w-10 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <EyeIcon className="w-4 h-4 text-color-3" />
                        ) : (
                          <EyeSlashIcon className="w-4 h-4 text-color-3" />
                        )}
                      </div>
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="password2"
                      >
                        {ConfigProvider.getTranslation("Password.NewAgain")}
                      </label>
                      <input
                        id="password2"
                        name="password2"
                        type={showPassword ? "text" : "password"}
                        className="w-full form-input"
                        placeholder="*******"
                        value={formData.password2}
                        onChange={handleChange}
                        required
                        maxLength={15}
                        disabled={loading}
                      />
                      <div
                        className="inline-flex items-center justify-center absolute right-0 top-3 h-full w-10 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <EyeIcon className="w-4 h-4 text-color-3" />
                        ) : (
                          <EyeSlashIcon className="w-4 h-4 text-color-3" />
                        )}
                      </div>
                    </div>
                    <div className="col-span-2">
                      <ul className="ml-10 my-2 list-disc">
                        <li>
                          {ConfigProvider.getTranslation(
                            "Password.MinMaxLength"
                          )}
                        </li>
                        <li>
                          {ConfigProvider.getTranslation(
                            "Password.RequiredUppcaseandLowercaseAndNumber"
                          )}
                        </li>
                        <li>
                          {ConfigProvider.getTranslation(
                            "Password.CaseSensetive"
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </section>
          )}
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
            <div className="flex justify-between">
              {formData._id &&
              checkRole(
                ConfigProvider.config.user,
                "SiteManageUsersResetGa"
              ) ? (
                <button
                  type="button"
                  disabled={loading}
                  className="btn bg-slate-500 hover:bg-slate-600 text-white ml-3 flex items-center"
                  onClick={() => {
                    setShowConfirm(true);
                  }}
                >
                  {loading ? (
                    <span>
                      <LoadingSpinner className="w-4 h-4 mb-1" />
                    </span>
                  ) : (
                    <span>
                      <EllipsisHorizontalCircleIcon className="w-4 h-4" />
                    </span>
                  )}
                  <span className="ml-2 uppercase">
                    {ConfigProvider.getTranslation("ResetTwoFactor")}
                  </span>
                </button>
              ) : (
                <>&nbsp;</>
              )}
              {formData._id &&
                checkRole(ConfigProvider.config.user, "ViewLogUsers") && (
                  <Link
                    to={`/updateLogs/users/${formData._id}`}
                    target="_blank"
                    title="Logs"
                    className="ml-2 bg-teal-500 text-white rounded-lg px-2 py-1 text-sm flex items-center"
                  >
                    <ArchiveBoxIcon className="w-5 h-5" />
                  </Link>
                )}
              <button
                type="submit"
                disabled={loading}
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-4 h-4 mb-1" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-4 h-4" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </footer>
      </form>

      {showConfirm && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{ConfigProvider.getTranslation("ToContinuePleaseConfirm")}</p>
            </>
          }
          confirm={() => {
            resetGA();
            setShowConfirm(false);
          }}
          callback={() => {
            setShowConfirm(false);
          }}
        />
      )}
    </div>
  );
}

export default UpsertAccount;

import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import { WelcomeBanner } from "../../components/WelcomeBanner";
import Alert from "../../components/Alert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import {
  ArchiveBoxIcon,
  ArrowRightCircleIcon,
  CloudArrowUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useToastr } from "../../context/ToastrContext";
import Confirm from "../../components/Confirm";
import CurrencyDropdown from "../../components/CurrencyDropdown";
import Dropdown from "../../components/Dropdown";
import ImgUpload from "../../components/ImgUpload";
import ColorInput from "../../components/ColorInput";

function UpsertJackpotDefault() {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const { _id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "JackpotsDefaultEdit")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    currency: "",
    startLimit: "",
    percent: "",
    minPayout: "",
    maxPayout: "",
    providers: [],
    tbwin: "",
    title1: "",
    title2: "",
    bgColor1: "",
    bgColor2: "",
    image: "",
    lan: ConfigProvider.config.lan,
    _id: _id ? _id : undefined,
  });

  const [messages, setMessages] = useState([]);
  const submitForm = (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      setMessages([]);
      Requests.postData("/jackpot/updateDefaultJackpot", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            setTimeout(() => {
              navigate(`/jackpot/defaults`);
            }, 1000);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [providers, setProviders] = useState(null);
  useEffect(() => {
    Requests.postData("/jackpot/getDefaultJackpotInfo", {
      _id: formData._id,
      lan: formData.lan,
    })
      .then((response) => {
        let res = response.data;
        if (res.type !== "success") {
          diagnosticResponse(res);
          ToastrContext.showToast({
            type: "error",
            text: res.message.length
              ? res.message
              : ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
        } else {
          setProviders(res.data.providers);
          if (res.data.item) {
            let newProviders = [];
            res.data.item.providers.forEach((x) =>
              newProviders.push(x.toString())
            );
            setFormData((prev) => {
              return {
                ...prev,
                name: res.data.item.name,
                currency: res.data.item.currency,
                startLimit: res.data.item.startLimit,
                percent: res.data.item.percent,
                minPayout: res.data.item.minPayout,
                maxPayout: res.data.item.maxPayout,
                providers: newProviders,
                tbwin: res.data.item.tbwin,
                title1: res.data.item.title1 ?? "",
                title2: res.data.item.title2 ?? "",
                bgColor1: res.data.item.bgColor1 ?? "",
                bgColor2: res.data.item.bgColor2 ?? "",
                image: res.data.item.image ?? "",
              };
            });
          }
        }
        setLoading(false);
      })
      .catch(() => {
        ToastrContext.showToast({
          type: "error",
          text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
        });
        document.getElementById("mainBody").scrollTo(0, 0);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [formData._id, formData.lan]);
  const [showConfirm, setShowConfirm] = useState(false);
  const deleteItem = () => {
    if (formData._id && !loading) {
      setLoading(true);
      Requests.postData("/jackpot/removeDefaultJackpot", {
        _id: formData._id,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });

            setShowConfirm(false);
            navigate("/jackpot/defaults");
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setLoading(false);
          setShowConfirm(false);
        });
    }
  };

  const allLanguages = ConfigProvider.config.languages;
  const cdnEndpoint = process.env.REACT_APP_CDN_ENDPOINT;
  const [clickUpload, setClickUpload] = useState(false);
  const handleImageClick = (id) => {
    setClickUpload(true);
  };
  const uploadFileSuccess = (name) => {
    setFormData({ ...formData, image: "/jackpot/" + name });
    Requests.postData("/jackpot/updateJackpotDefaultImg", {
      _id: formData._id,
      name: "jackpot/" + name,
    })
      .then((response) => {
        let res = response.data;
        if (res.type !== "success") {
          diagnosticResponse(res) &&
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
        } else {
          ToastrContext.showToast({
            type: "success",
            text: res.message.length
              ? res.message
              : ConfigProvider.getTranslation("Forms.SuccessMessage"),
          });
        }
        setLoading(false);
      })
      .catch(() => {
        ToastrContext.showToast({
          type: "error",
          text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
        });
        setLoading(false);
      });
  };
  return (
    <div className="grow max-w-lg mx-auto lg:mt-20">
      <WelcomeBanner>
        {ConfigProvider.getTranslation("DefaultJackpot")}
        {" / "}
        {formData._id
          ? ConfigProvider.getTranslation("Edit")
          : ConfigProvider.getTranslation("Add")}
        💫
      </WelcomeBanner>
      <form className="w-full" autoComplete="off" onSubmit={submitForm}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
            {ConfigProvider.getTranslation("General")}
          </h2>
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>

          <section>
            <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="name"
                >
                  {ConfigProvider.getTranslation("Name")}
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("Name")}
                  required
                  maxLength={50}
                  disabled={loading}
                />
              </div>
              {formData._id === undefined && (
                <div className="relative col-span-2">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="currency"
                  >
                    {ConfigProvider.getTranslation("Currency")}
                  </label>
                  <CurrencyDropdown
                    name="currency"
                    selected={formData.currency}
                    setSelected={(val) => {
                      setFormData((prev) => {
                        return { ...prev, currency: val };
                      });
                    }}
                  />
                </div>
              )}
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="startLimit"
                >
                  {ConfigProvider.getTranslation("StartLimit")}
                </label>
                <input
                  id="startLimit"
                  name="startLimit"
                  type="number"
                  value={formData.startLimit}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("StartLimit")}
                  required
                  disabled={loading}
                  step={0.01}
                  min={0}
                />
              </div>
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="percent"
                >
                  % {ConfigProvider.getTranslation("Percent")}
                </label>
                <input
                  id="percent"
                  name="percent"
                  type="number"
                  value={formData.percent}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("StartLimit")}
                  required
                  disabled={loading}
                  step={0.01}
                  min={0.01}
                  max={100}
                />
              </div>
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="minPayout"
                >
                  {ConfigProvider.getTranslation("MinPayout")}
                </label>
                <input
                  id="minPayout"
                  name="minPayout"
                  type="number"
                  value={formData.minPayout}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("MinPayout")}
                  required
                  disabled={loading}
                  min={1}
                />
              </div>
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="maxPayout"
                >
                  {ConfigProvider.getTranslation("MaxPayout")}
                </label>
                <input
                  id="maxPayout"
                  name="maxPayout"
                  type="number"
                  value={formData.maxPayout}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("MaxPayout")}
                  required
                  disabled={loading}
                  min={1}
                />
              </div>
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="tbwin"
                >
                  {ConfigProvider.getTranslation("NoWinWhenStakeExceeds")}
                </label>
                <input
                  id="tbwin"
                  name="tbwin"
                  type="number"
                  value={formData.tbwin}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation(
                    "NoWinWhenStakeExceeds"
                  )}
                  disabled={loading}
                  required
                />
              </div>
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="provider"
                >
                  {ConfigProvider.getTranslation("Provider")}
                </label>
                {providers && (
                  <Dropdown
                    name="provider"
                    items={providers}
                    selected={formData.providers}
                    setSelected={(item) =>
                      setFormData((prev) => {
                        if (prev.providers.includes(item)) {
                          return {
                            ...prev,
                            providers: prev.providers.filter(
                              (val) => val !== item
                            ),
                          };
                        } else {
                          return {
                            ...prev,
                            providers: [...prev.providers, item],
                          };
                        }
                      })
                    }
                    align="top"
                    multi={true}
                  />
                )}
              </div>
              <div className="relative">
                <label className="block text-sm font-medium mb-1" htmlFor="lan">
                  {ConfigProvider.getTranslation("Language")}
                </label>
                <Dropdown
                  name="lan"
                  items={allLanguages}
                  selected={formData.lan}
                  setSelected={(val) => {
                    setFormData({ ...formData, lan: val });
                  }}
                />
              </div>

              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="title1"
                >
                  {`${ConfigProvider.getTranslation("Title")} 1:`}
                </label>
                <input
                  id="title1"
                  name="title1"
                  type="text"
                  value={formData.title1}
                  onChange={handleChange}
                  className="w-full form-input"
                  maxLength={50}
                  disabled={loading}
                />
              </div>

              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="title2"
                >
                  {`${ConfigProvider.getTranslation("Title")} 2:`}
                </label>
                <input
                  id="title2"
                  name="title2"
                  type="text"
                  value={formData.title2}
                  onChange={handleChange}
                  className="w-full form-input"
                  maxLength={50}
                  disabled={loading}
                />
              </div>

              <ColorInput
                title={`${ConfigProvider.getTranslation("BackgroundColor")}-1:`}
                name="bgColor1"
                val={formData.bgColor1}
                loading={loading}
                handleChange={(val) => {
                  setFormData((prev) => {
                    return { ...prev, bgColor1: val };
                  });
                }}
              />
              <ColorInput
                title={`${ConfigProvider.getTranslation("BackgroundColor")}-2:`}
                name="bgColor2"
                val={formData.bgColor2}
                loading={loading}
                handleChange={(val) => {
                  setFormData((prev) => {
                    return { ...prev, bgColor2: val };
                  });
                }}
              />
              {formData._id && (
                <>
                  <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                    {ConfigProvider.getTranslation("Image")}
                  </h2>
                  <section>
                    <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                      <div className="relative col-span-2">
                        <div className="p-1 bg-white rounded inline-block my-2">
                          <img
                            srcSet={
                              cdnEndpoint + "/upload/img/" + formData.image
                            }
                            alt="No IMG"
                            className="min-w-[100px] max-w-[200px]"
                          />
                        </div>

                        <button
                          type="button"
                          onClick={() => {
                            handleImageClick();
                          }}
                          disabled={loading}
                          className="btn bg-amber-500 hover:bg-amber-600 text-white ml-3 flex items-center"
                        >
                          <span className="mr-2 uppercase">
                            {ConfigProvider.getTranslation("UploadImage")}
                          </span>
                          {loading ? (
                            <span>
                              <LoadingSpinner className="w-4 h-4 mb-1" />
                            </span>
                          ) : (
                            <span>
                              <CloudArrowUpIcon className="w-4 h-4" />
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </section>
                </>
              )}
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
            <div className="flex justify-between">
              {formData._id ? (
                <button
                  type="button"
                  disabled={loading}
                  className="btn bg-rose-500 hover:bg-rose-600 text-white ml-3 flex items-center"
                  onClick={() => {
                    setShowConfirm(true);
                  }}
                >
                  {loading ? (
                    <span>
                      <LoadingSpinner className="w-4 h-4 mb-1" />
                    </span>
                  ) : (
                    <span>
                      <XMarkIcon className="w-4 h-4" />
                    </span>
                  )}
                  <span className="ml-2 uppercase">
                    {ConfigProvider.getTranslation("Delete")}
                  </span>
                </button>
              ) : (
                <>&nbsp;</>
              )}
              {formData._id &&
                checkRole(
                  ConfigProvider.config.user,
                  "ViewLogJackpotsDefaults"
                ) && (
                  <Link
                    to={`/updateLogs/jackpotsDefaults/${formData._id}`}
                    target="_blank"
                    title="Logs"
                    className="ml-2 bg-teal-500 text-white rounded-lg px-2 py-1 text-sm flex items-center"
                  >
                    <ArchiveBoxIcon className="w-5 h-5" />
                  </Link>
                )}
              <button
                type="submit"
                disabled={loading}
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-4 h-4 mb-1" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-4 h-4" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </footer>
      </form>
      {formData._id && (
        <ImgUpload
          loading={loading}
          path="jackpot"
          callback={(val) => {
            uploadFileSuccess(val);
          }}
          clickUpload={clickUpload}
          setClickUpload={setClickUpload}
        />
      )}

      {showConfirm && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{ConfigProvider.getTranslation("ToContinuePleaseConfirm")}</p>
            </>
          }
          confirm={() => {
            deleteItem();
            setShowConfirm(false);
          }}
          callback={() => {
            setShowConfirm(false);
          }}
        />
      )}
    </div>
  );
}

export default UpsertJackpotDefault;

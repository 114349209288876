import React, { useEffect, useState } from "react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";

import { useConfig } from "../../context/ConfigContext";
import { usePrefences } from "../../context/PrefencesContext";
import { Link, useNavigate } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import Alert from "../../components/Alert";
import SearchFilter from "../../components/SearchFilter";
import PaginationClassic from "../../components/PaginationClassic";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { useToastr } from "../../context/ToastrContext";
import { ArchiveBoxIcon } from "@heroicons/react/24/outline";

function LimitClasses() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "SportsManageLimitClasses")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    page: 1,
  });
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = { ...formData };
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.search = search;
      Requests.postData("/sports/getLimitClasses", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [formData.page, PrefencesProvider.prefences.offset]);

  return (
    <>
      {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (formData.page !== 1) {
            setFormData((prev) => {
              return { ...prev, page: 1 };
            });
          } else {
            getItems();
          }
        }}
        className=""
      >
        {messages.length > 0 && (
          <div className="mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
        )}
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-5">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
              {ConfigProvider.getTranslation("LimitClasses")} ✨
            </h1>
          </div>
        </div>
        {/* More actions */}
        <div className="lg:flex lg:justify-between lg:items-center mb-5">
          {/* Left side */}
          <div className="mb-4 lg:mb-0"></div>
          {/* Right side */}
          <div className="flex flex-col lg:grid lg:grid-flow-col lg:auto-cols-max justify-end gap-2">
            {/* Search form */}
            <SearchFilter
              placeholder={ConfigProvider.getTranslation("Search")}
              search={search}
              setSearch={setSearch}
            />

            <button
              type="submit"
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
              <span className="ml-2">
                {ConfigProvider.getTranslation("Button.Send")}
              </span>
            </button>
          </div>
        </div>
      </form>
      {/* Table */}
      <LimitClassesTable data={data} loading={loading} getItems={getItems} />

      {/* Pagination */}
      <div className="mt-8">
        <PaginationClassic
          page={formData.page}
          setPage={(val) => {
            setFormData((prev) => {
              return { ...prev, page: val };
            });
          }}
          totalItems={totalItems}
        />
      </div>
    </>
  );
}
function LimitClassesTable({ data, loading, getItems }) {
  const ConfigProvider = useConfig();

  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("LimitClasses")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th
                  rowSpan={2}
                  className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap"
                >
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Class")}
                  </div>
                </th>
                <th
                  colSpan={2}
                  className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap"
                >
                  <div className="grow font-semibold text-center">{`${ConfigProvider.getTranslation(
                    "General"
                  )} - ${ConfigProvider.getTranslation("PreGame")}`}</div>
                </th>
                <th
                  colSpan={2}
                  className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap bg-indigo-500"
                >
                  <div className="grow font-semibold text-center text-slate-100">{`${ConfigProvider.getTranslation(
                    "General"
                  )} - ${ConfigProvider.getTranslation("Live")}`}</div>
                </th>
                <th
                  colSpan={2}
                  className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap"
                >
                  <div className="grow font-semibold text-center">{`${ConfigProvider.getTranslation(
                    "Shop"
                  )} - ${ConfigProvider.getTranslation("PreGame")}`}</div>
                </th>
                <th
                  colSpan={2}
                  className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap bg-indigo-500"
                >
                  <div className="grow font-semibold text-center text-slate-100">{`${ConfigProvider.getTranslation(
                    "Shop"
                  )} - ${ConfigProvider.getTranslation("Live")}`}</div>
                </th>
                <th
                  colSpan={2}
                  className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap"
                >
                  <div className="grow font-semibold text-center">{`${ConfigProvider.getTranslation(
                    "Card"
                  )} - ${ConfigProvider.getTranslation("PreGame")}`}</div>
                </th>
                <th
                  colSpan={2}
                  className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap bg-indigo-500"
                >
                  <div className="grow font-semibold text-center text-slate-100">{`${ConfigProvider.getTranslation(
                    "Card"
                  )} - ${ConfigProvider.getTranslation("Live")}`}</div>
                </th>
              </tr>
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("SingleBetLimit")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("MultibleBetLimit")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("SingleBetLimit")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("MultibleBetLimit")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("SingleBetLimit")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("MultibleBetLimit")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("SingleBetLimit")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("MultibleBetLimit")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("SingleBetLimit")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("MultibleBetLimit")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("SingleBetLimit")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("MultibleBetLimit")}
                  </div>
                </th>
              </tr>
            </thead>
            {!loading && data && data.length === 0 && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                <tr>
                  <td colSpan="13">
                    <NoData />
                  </td>
                </tr>
              </tbody>
            )}
            {loading && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                <tr>
                  <td colSpan="13" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              </tbody>
            )}

            {!loading &&
              data.map((item) => (
                <LimitClassesTableItem
                  key={item._id}
                  item={item}
                  loading={loading}
                  getItems={getItems}
                />
              ))}
          </table>
        </div>
      </div>
    </div>
  );
}
function LimitClassesTableItem({ item, loading, getItems }) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();

  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [formData, setFormData] = useState({
    _id: item._id,
    preSingleLimitGeneral: 0,
    preMultibleLimitGeneral: 0,
    liveSingleLimitGeneral: 0,
    liveMultibleLimitGeneral: 0,
    preSingleLimitShop: 0,
    preMultibleLimitShop: 0,
    liveSingleLimitShop: 0,
    liveMultibleLimitShop: 0,
    preSingleLimitCard: 0,
    preMultibleLimitCard: 0,
    liveSingleLimitCard: 0,
    liveMultibleLimitCard: 0,
  });
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [dataLoading, setDataLoading] = useState(false);
  const getData = () => {
    if (!dataLoading) {
      setDataLoading(true);

      Requests.postData("/sports/getLimitClass", { _id: item._id })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
          } else {
            setFormData({
              ...formData,
              preSingleLimitGeneral: res.data.preSingleLimitGeneral,
              preMultibleLimitGeneral: res.data.preMultibleLimitGeneral,
              liveSingleLimitGeneral: res.data.liveSingleLimitGeneral,
              liveMultibleLimitGeneral: res.data.liveMultibleLimitGeneral,
              preSingleLimitShop: res.data.preSingleLimitShop,
              preMultibleLimitShop: res.data.preMultibleLimitShop,
              liveSingleLimitShop: res.data.liveSingleLimitShop,
              liveMultibleLimitShop: res.data.liveMultibleLimitShop,
              preSingleLimitCard: res.data.preSingleLimitCard,
              preMultibleLimitCard: res.data.preMultibleLimitCard,
              liveSingleLimitCard: res.data.liveSingleLimitCard,
              liveMultibleLimitCard: res.data.liveMultibleLimitCard,
            });
          }
          setDataLoading(false);
        })
        .catch(() => {
          document.getElementById("mainBody").scrollTo(0, 0);
          setDataLoading(false);
        });
    }
  };
  useEffect(() => {
    if (descriptionOpen) {
      getData();
    }
    // eslint-disable-next-line
  }, [descriptionOpen]);

  const updateLimitClass = (e) => {
    e.preventDefault();

    if (!dataLoading) {
      setDataLoading(true);

      Requests.postData("/sports/updateLimitClass", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
            setDescriptionOpen(false);
            getItems();
          }
          setDataLoading(false);
        })
        .catch(() => {
          setDataLoading(false);
        });
    }
  };

  return (
    <tbody className="text-sm">
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <Link
            onClick={() => setDescriptionOpen(!descriptionOpen)}
            className="font-semibold text-sky-500 dark:text-sky-400 "
          >
            {item.classname}
          </Link>
        </td>

        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap bg-emerald-500">
          <div className="text-center text-slate-100 font-semibold">
            {item.general.preSingleLimit}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap bg-indigo-500">
          <div className="text-center text-slate-100 font-semibold">
            {item.general.preMultibleLimit}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap bg-emerald-500">
          <div className="text-center text-slate-100 font-semibold">
            {item.general.liveSingleLimit}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap bg-indigo-500">
          <div className="text-center text-slate-100 font-semibold">
            {item.general.liveMultibleLimit}
          </div>
        </td>

        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap bg-emerald-500">
          <div className="text-center text-slate-100 font-semibold">
            {item.shop.preSingleLimit}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap bg-indigo-500">
          <div className="text-center text-slate-100 font-semibold">
            {item.shop.preMultibleLimit}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap bg-emerald-500">
          <div className="text-center text-slate-100 font-semibold">
            {item.shop.liveSingleLimit}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap bg-indigo-500">
          <div className="text-center text-slate-100 font-semibold">
            {item.shop.liveMultibleLimit}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap bg-emerald-500">
          <div className="text-center text-slate-100 font-semibold">
            {item.card.preSingleLimit}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap bg-indigo-500">
          <div className="text-center text-slate-100 font-semibold">
            {item.card.preMultibleLimit}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap bg-emerald-500">
          <div className="text-center text-slate-100 font-semibold">
            {item.card.liveSingleLimit}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap bg-indigo-500">
          <div className="text-center text-slate-100 font-semibold">
            {item.card.liveMultibleLimit}
          </div>
        </td>
      </tr>
      {descriptionOpen && (
        <tr
          id={`description-${item.id}`}
          role="region"
          className={`${!descriptionOpen && "hidden"}`}
        >
          <td
            colSpan="13"
            className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3"
          >
            <div className="w-full bg-slate-50 dark:bg-slate-900/30 dark:text-slate-400 p-3 -mt-3">
              <form
                onSubmit={(e) => {
                  updateLimitClass(e);
                }}
                className="p-6 space-y-6"
              >
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                  {`${ConfigProvider.getTranslation(
                    "General"
                  )} - ${ConfigProvider.getTranslation("PreGame")}`}
                </h2>
                <section>
                  <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="preSingleLimitGeneral"
                      >
                        {ConfigProvider.getTranslation("SingleBetLimit")}
                      </label>
                      <input
                        id="preSingleLimitGeneral"
                        name="preSingleLimitGeneral"
                        type="number"
                        value={formData.preSingleLimitGeneral}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "SingleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="preMultibleLimitGeneral"
                      >
                        {ConfigProvider.getTranslation("MultibleBetLimit")}
                      </label>
                      <input
                        id="preMultibleLimitGeneral"
                        name="preMultibleLimitGeneral"
                        type="number"
                        value={formData.preMultibleLimitGeneral}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "MultibleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </section>
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                  {`${ConfigProvider.getTranslation(
                    "General"
                  )} - ${ConfigProvider.getTranslation("Live")}`}
                </h2>
                <section>
                  <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="liveSingleLimitGeneral"
                      >
                        {ConfigProvider.getTranslation("SingleBetLimit")}
                      </label>
                      <input
                        id="liveSingleLimitGeneral"
                        name="liveSingleLimitGeneral"
                        type="number"
                        value={formData.liveSingleLimitGeneral}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "SingleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="liveMultibleLimitGeneral"
                      >
                        {ConfigProvider.getTranslation("MultibleBetLimit")}
                      </label>
                      <input
                        id="liveMultibleLimitGeneral"
                        name="liveMultibleLimitGeneral"
                        type="number"
                        value={formData.liveMultibleLimitGeneral}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "MultibleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </section>

                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                  {`${ConfigProvider.getTranslation(
                    "Shop"
                  )} - ${ConfigProvider.getTranslation("PreGame")}`}
                </h2>
                <section>
                  <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="preSingleLimitShop"
                      >
                        {ConfigProvider.getTranslation("SingleBetLimit")}
                      </label>
                      <input
                        id="preSingleLimitShop"
                        name="preSingleLimitShop"
                        type="number"
                        value={formData.preSingleLimitShop}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "SingleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="preMultibleLimitShop"
                      >
                        {ConfigProvider.getTranslation("MultibleBetLimit")}
                      </label>
                      <input
                        id="preMultibleLimitShop"
                        name="preMultibleLimitShop"
                        type="number"
                        value={formData.preMultibleLimitShop}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "MultibleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </section>
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                  {`${ConfigProvider.getTranslation(
                    "Shop"
                  )} - ${ConfigProvider.getTranslation("Live")}`}
                </h2>
                <section>
                  <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="liveSingleLimitShop"
                      >
                        {ConfigProvider.getTranslation("SingleBetLimit")}
                      </label>
                      <input
                        id="liveSingleLimitShop"
                        name="liveSingleLimitShop"
                        type="number"
                        value={formData.liveSingleLimitShop}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "SingleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="liveMultibleLimitShop"
                      >
                        {ConfigProvider.getTranslation("MultibleBetLimit")}
                      </label>
                      <input
                        id="liveMultibleLimitShop"
                        name="liveMultibleLimitShop"
                        type="number"
                        value={formData.liveMultibleLimitShop}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "MultibleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </section>

                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                  {`${ConfigProvider.getTranslation(
                    "Card"
                  )} - ${ConfigProvider.getTranslation("PreGame")}`}
                </h2>
                <section>
                  <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="preSingleLimitCard"
                      >
                        {ConfigProvider.getTranslation("SingleBetLimit")}
                      </label>
                      <input
                        id="preSingleLimitCard"
                        name="preSingleLimitCard"
                        type="number"
                        value={formData.preSingleLimitCard}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "SingleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="preMultibleLimitCard"
                      >
                        {ConfigProvider.getTranslation("MultibleBetLimit")}
                      </label>
                      <input
                        id="preMultibleLimitCard"
                        name="preMultibleLimitCard"
                        type="number"
                        value={formData.preMultibleLimitCard}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "MultibleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </section>
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                  {`${ConfigProvider.getTranslation(
                    "Card"
                  )} - ${ConfigProvider.getTranslation("Live")}`}
                </h2>
                <section>
                  <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="liveSingleLimitCard"
                      >
                        {ConfigProvider.getTranslation("SingleBetLimit")}
                      </label>
                      <input
                        id="liveSingleLimitCard"
                        name="liveSingleLimitCard"
                        type="number"
                        value={formData.liveSingleLimitCard}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "SingleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="liveMultibleLimitCard"
                      >
                        {ConfigProvider.getTranslation("MultibleBetLimit")}
                      </label>
                      <input
                        id="liveMultibleLimitCard"
                        name="liveMultibleLimitCard"
                        type="number"
                        value={formData.liveMultibleLimitCard}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "MultibleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </section>

                <footer>
                  <div className="flex px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                    {checkRole(
                      ConfigProvider.config.user,
                      "ViewLogLimitSettings"
                    ) ? (
                      <Link
                        to={`/updateLogs/limitSettings/${formData._id}`}
                        target="_blank"
                        title="Logs"
                        className="ml-2 bg-teal-500 text-white rounded-lg px-2 py-1 text-sm flex items-center"
                      >
                        <ArchiveBoxIcon className="w-5 h-5" />
                      </Link>
                    ) : (
                      <></>
                    )}
                    <button
                      type="submit"
                      disabled={dataLoading}
                      className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center grow"
                    >
                      <span className="mr-2 uppercase">
                        {ConfigProvider.getTranslation("Button.Save")}
                      </span>
                      {dataLoading ? (
                        <span>
                          <LoadingSpinner className="w-4 h-4 mb-1" />
                        </span>
                      ) : (
                        <span>
                          <ArrowRightCircleIcon className="w-4 h-4" />
                        </span>
                      )}
                    </button>
                  </div>
                </footer>
              </form>
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
}
export default LimitClasses;

import React, { useEffect, useState } from "react";
import {
  ArrowPathIcon,
  ArrowRightCircleIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid";

import { useConfig } from "../../context/ConfigContext";
import { usePrefences } from "../../context/PrefencesContext";
import { Link, useNavigate } from "react-router-dom";
import {
  checkRole,
  classNames,
  diagnosticResponse,
} from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import Alert from "../../components/Alert";
import SearchFilter from "../../components/SearchFilter";
import PaginationClassic from "../../components/PaginationClassic";
import SortByItem from "../../components/SortByItems";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Confirm from "../../components/Confirm";
import { useToastr } from "../../context/ToastrContext";

import Dropdown from "../../components/Dropdown";
import DropdownFilter from "../../components/DropdownFilter";
import { ArchiveBoxIcon } from "@heroicons/react/24/outline";

function SportList() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "SportsManageSports")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    page: 1,
    sort: 0,
    sortBy: 0,
    stat: "-1",
  });
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = { ...formData };
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.search = search;
      Requests.postData("/sports/getSports", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setOddValues(res.oddValues);

            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);
  const [oddValues, setOddValues] = useState(false);
  const allStat = {
    "-1": ConfigProvider.getTranslation("Filter.All"),
    1: ConfigProvider.getTranslation("Active"),
    0: ConfigProvider.getTranslation("Passive"),
  };
  const [showConfirm, setShowConfirm] = useState(false);
  const resetMins = () => {
    if (!loading) {
      Requests.postData("/sports/resetSportsMin", {})
        .then((response) => {
          const res = response.data;
          diagnosticResponse(res) && res.type === "success" && getItems();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  return (
    <>
      {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (formData.page !== 1) {
            setFormData((prev) => {
              return { ...prev, page: 1 };
            });
          } else {
            getItems();
          }
        }}
        className=""
      >
        {messages.length > 0 && (
          <div className="mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
        )}
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-5">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
              {ConfigProvider.getTranslation("Sports")} ✨
            </h1>
          </div>

          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            <button
              type="button"
              className="btn bg-rose-500 hover:bg-rose-600 text-white"
              onClick={() => setShowConfirm(true)}
            >
              <ArrowPathIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
              <span className="ml-2">
                {`${ConfigProvider.getTranslation(
                  "Reset"
                )} - ${ConfigProvider.getTranslation("Min")}`}
              </span>
            </button>
          </div>
        </div>
        {/* More actions */}
        <div className="lg:flex lg:justify-between lg:items-center mb-5">
          {/* Left side */}
          <div className="mb-4 lg:mb-0"></div>
          {/* Right side */}
          <div className="flex flex-col lg:grid lg:grid-flow-col lg:auto-cols-max justify-end gap-2">
            {/* Search form */}
            <SearchFilter
              placeholder={ConfigProvider.getTranslation("Search")}
              search={search}
              setSearch={setSearch}
            />
            <DropdownFilter align="right">
              <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                {ConfigProvider.getTranslation("Status")}
              </div>
              <ul className="mb-4">
                {Object.keys(allStat).map((item) => (
                  <li key={item} className="py-1 px-3">
                    <label className="flex items-center cursor-pointer">
                      <input
                        id={item}
                        name={item}
                        type="checkbox"
                        className="form-checkbox"
                        checked={formData.stat === item}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            stat: item,
                          })
                        }
                        disabled={loading}
                      />
                      <span className="text-sm font-medium ml-2">
                        {allStat[item]}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
            </DropdownFilter>

            <button
              type="submit"
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
              <span className="ml-2">
                {ConfigProvider.getTranslation("Button.Send")}
              </span>
            </button>
          </div>
        </div>
      </form>
      {/* Table */}
      <SportListTable
        data={data}
        loading={loading}
        formData={formData}
        setFormData={setFormData}
        getItems={getItems}
        oddValues={oddValues}
        setData={setData}
      />
      {/* Pagination */}
      <div className="mt-8">
        <PaginationClassic
          page={formData.page}
          setPage={(val) => {
            setFormData((prev) => {
              return { ...prev, page: val };
            });
          }}
          totalItems={totalItems}
        />
      </div>
      {showConfirm && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{`${ConfigProvider.getTranslation(
                "Reset"
              )} - ${ConfigProvider.getTranslation("Min")}`}</p>
            </>
          }
          confirm={() => {
            resetMins();
            setShowConfirm(false);
          }}
          callback={() => {
            setShowConfirm(false);
          }}
        />
      )}{" "}
    </>
  );
}
function SportListTable({
  data,
  loading,
  formData,
  setFormData,
  getItems,
  oddValues,
  setData,
}) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmItem, setConfirmItem] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const setIsShowSport = () => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/sports/setIsShowSport", {
        id: confirmItem.data.id,
        isShow: confirmItem.type === "isShow" ? !confirmItem.data.isShow : null,
        odd: confirmItem.type === "odd" ? confirmItem.data.odd : null,
        min: confirmItem.type === "min" ? confirmItem.data.min : null,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });

            setShowConfirm(false);
            setConfirmItem(null);
            setTimeout(() => {
              getItems();
            }, 100);
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
          setShowConfirm(false);
          setConfirmItem(null);
        });
    }
  };

  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("Sports")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-full">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Sport")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Status")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Min")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap max-w-[120px] min-w-[120px]">
                  &nbsp;
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Rate")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap max-w-[120px] min-w-[120px]">
                  &nbsp;
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  &nbsp;
                </th>
              </tr>
            </thead>
            {!loading && data && data.length === 0 && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                <tr>
                  <td colSpan="7">
                    <NoData />
                  </td>
                </tr>
              </tbody>
            )}
            {loading && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                <tr>
                  <td colSpan="7" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading &&
              data.map((item) => (
                <SportListTableItem
                  key={item.id}
                  item={item}
                  setData={setData}
                  loading={loading}
                  setConfirmItem={setConfirmItem}
                  setShowConfirm={setShowConfirm}
                  oddValues={oddValues}
                />
              ))}
          </table>
        </div>
      </div>
      {showConfirm && confirmItem !== null && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{confirmItem.data.name}</p>
              {confirmItem.type === "isShow" && (
                <p>{`${ConfigProvider.getTranslation("Status")} -> ${
                  confirmItem.data.isShow
                    ? ConfigProvider.getTranslation("Passive")
                    : ConfigProvider.getTranslation("Active")
                }`}</p>
              )}
              {confirmItem.type === "odd" && (
                <p>{`${ConfigProvider.getTranslation("Rate")} -> ${
                  confirmItem.data.odd
                }`}</p>
              )}
              {confirmItem.type === "min" && (
                <p>{`${ConfigProvider.getTranslation("Min")} -> ${
                  confirmItem.data.min === "0"
                    ? ConfigProvider.getTranslation("Undefined")
                    : confirmItem.data.min
                }`}</p>
              )}
            </>
          }
          confirm={() => {
            setIsShowSport();
          }}
          callback={() => {
            setShowConfirm(false);
            setConfirmItem(null);
          }}
        />
      )}
    </div>
  );
}

function SportListTableItem({
  item,
  setData,
  loading,
  setConfirmItem,
  setShowConfirm,
  oddValues,
}) {
  const ConfigProvider = useConfig();
  const [editOdd, setEditOdd] = useState(false);
  const [editMin, setEditMin] = useState(false);
  const minValues = {
    0: ConfigProvider.getTranslation("Undefined"),
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
  };
  return (
    <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left text-slate-900 dark:text-slate-100">
            {item.name}
          </div>
        </td>

        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <Link
            className={classNames(
              "inline-flex font-medium rounded-full text-center px-2.5 py-0.5",
              item.isShow
                ? "bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400"
                : "bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
            )}
            onClick={() => {
              setConfirmItem({ type: "isShow", data: item });
              setShowConfirm(true);
            }}
          >
            {item.isShow
              ? ConfigProvider.getTranslation("Active")
              : ConfigProvider.getTranslation("Passive")}
          </Link>
        </td>
        <td
          colSpan={2}
          className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
        >
          {editMin ? (
            <form
              className="flex space-x-2"
              onSubmit={(e) => {
                e.preventDefault();
                if (item.min) {
                  setConfirmItem({ type: "min", data: item });
                  setShowConfirm(true);
                } else {
                  setEditMin(false);
                }
              }}
            >
              <Dropdown
                name="min"
                items={minValues}
                selected={item.min}
                setSelected={(val) =>
                  setData((prev) => {
                    const updatedItems = prev.map((subItem) => {
                      if (subItem.id === item.id) {
                        return {
                          ...subItem,
                          min: val,
                        };
                      }
                      return subItem;
                    });
                    return updatedItems;
                  })
                }
                disabled={loading}
              />
              <button
                type="submit"
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
              >
                <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                <span className="ml-2">
                  {ConfigProvider.getTranslation("Button.Save")}
                </span>
              </button>
            </form>
          ) : (
            <div className="flex items-center space-x-2">
              <span className="font-semibold mx-2 grow">
                {item.min ?? ConfigProvider.getTranslation("Undefined")}
              </span>
              <button
                type="button"
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                onClick={() => setEditMin(true)}
              >
                <PencilSquareIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
              </button>
            </div>
          )}
        </td>
        <td
          colSpan={2}
          className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
        >
          {editOdd ? (
            <form
              className="flex space-x-2"
              onSubmit={(e) => {
                e.preventDefault();
                setConfirmItem({ type: "odd", data: item });
                setShowConfirm(true);
              }}
            >
              <Dropdown
                name="rate"
                items={oddValues}
                selected={item.odd}
                setSelected={(val) =>
                  setData((prev) => {
                    const updatedItems = prev.map((subItem) => {
                      if (subItem.id === item.id) {
                        return {
                          ...subItem,
                          odd: val,
                        };
                      }
                      return subItem;
                    });
                    return updatedItems;
                  })
                }
                disabled={loading}
              />
              <button
                type="submit"
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
              >
                <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                <span className="ml-2">
                  {ConfigProvider.getTranslation("Button.Save")}
                </span>
              </button>
            </form>
          ) : (
            <div className="flex items-center space-x-2">
              <span className="font-semibold mx-2 grow">{item.odd}</span>
              <button
                type="button"
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                onClick={() => setEditOdd(true)}
              >
                <PencilSquareIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
              </button>
            </div>
          )}
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          {checkRole(ConfigProvider.config.user, "ViewLogBlockAndOdd") && (
            <Link
              to={`/updateLogs/blockAndOdd/${item.blockKey}`}
              target="_blank"
              title="Logs"
              className="ml-2 bg-teal-500 text-white rounded-lg px-2 py-1 text-sm flex items-center"
            >
              <ArchiveBoxIcon className="w-5 h-5" />
            </Link>
          )}
        </td>
      </tr>
    </tbody>
  );
}
export default SportList;

import React, { useState, useEffect } from "react";
import { usePrefences } from "../../context/PrefencesContext";
import { useConfig } from "../../context/ConfigContext";
import Alert from "../../components/Alert";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import SortByItem from "../../components/SortByItems";
import Requests from "../../helpers/Requests";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

import { useNavigate, useParams } from "react-router-dom";
import { CalendarIcon } from "@heroicons/react/24/outline";
function ReportEventDetail() {
  const ConfigProvider = useConfig();
  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "ReportMatchReportsList")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    id: id,
  });

  const [info, setInfo] = useState(null);
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = { ...formData };
      Requests.postData("/report/getEventsReportDetail", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setData(res.data.markets);
            setInfo(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);

  return ConfigProvider.config.user !== null ? (
    <>
      <div className="flex space-x-4">
        <div className="relative grow min-w-0">
          {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              getItems();
            }}
            className=""
          >
            {messages.length > 0 && (
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>
            )}
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                  {ConfigProvider.getTranslation("MatchReports")} ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                <button
                  type="submit"
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                  <span className="ml-2">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                </button>
              </div>
            </div>
            {/* More actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left side */}
              <div className="mb-4 grow flex flex-col lg:flex-row lg:mb-0 gap-4 mr-2"></div>
              {/* Right side */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-end gap-2"></div>
            </div>

            {/* Table */}
            <ReportEventDetailTable
              data={data}
              loading={loading}
              formData={formData}
              setFormData={setFormData}
              info={info}
            />
          </form>
        </div>
      </div>
    </>
  ) : null;
}
function ReportEventDetailTable({
  data,
  loading,
  formData,
  setFormData,
  info,
}) {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        {info && (
          <div className="font-medium text-slate-800 dark:text-slate-100 flex flex-col">
            <div className="flex items-center text-xs text-slate-700 dark:text-slate-500">
              <CalendarIcon className="w-4 h-4 mr-1" />
              {new Intl.DateTimeFormat("default", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZone: PrefencesProvider.prefences.timezone,
              }).format(new Date(info.eventdate))}
            </div>
            <h2 className="font-semibold text-slate-800 dark:text-slate-100">
              {info.label}
            </h2>
            <div className="text-xs text-slate-700 dark:text-slate-500">
              {`${info.sport} / ${info.catName}`}
            </div>
          </div>
        )}
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="border-l border-b border-slate-200 dark:border-slate-700 px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  {info && (
                    <div className="font-medium">
                      <div className="text-xs">{`${info.className}`}</div>
                    </div>
                  )}
                </th>
                <th
                  colSpan="2"
                  className="border-l border-b border-slate-200 dark:border-slate-700 px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap"
                >
                  <div className="text-center">
                    {ConfigProvider.getTranslation("Total")}
                  </div>
                </th>
                <th
                  colSpan="2"
                  className="border-l border-b border-slate-200 dark:border-slate-700 px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap"
                >
                  <div className="text-center">
                    {ConfigProvider.getTranslation("BetSlip.Single")}
                  </div>
                </th>
                <th
                  colSpan="2"
                  className="border-l border-b border-slate-200 dark:border-slate-700 px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap"
                >
                  <div className="text-center">
                    {ConfigProvider.getTranslation("BetSlip.Kombination")}
                  </div>
                </th>
              </tr>
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Market")}
                  </div>
                </th>

                <th className="border-l border-slate-200 dark:border-slate-700 px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={0}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Count")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Sum")}
                    </div>
                  </SortByItem>
                </th>
                <th className="border-l border-slate-200 dark:border-slate-700 px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={2}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Count")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={3}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Sum")}
                    </div>
                  </SortByItem>
                </th>
                <th className="border-l border-slate-200 dark:border-slate-700 px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={4}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Count")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={5}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Sum")}
                    </div>
                  </SortByItem>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              {!loading && data && data.length === 0 && (
                <tr>
                  <td colSpan="7">
                    <NoData />
                  </td>
                </tr>
              )}
              {loading ? (
                <tr>
                  <td colSpan="7" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              ) : (
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-medium text-slate-800 dark:text-slate-100 flex flex-col">
                          {item.name}
                        </div>
                      </td>

                      <td className="border-l border-slate-200 dark:border-slate-700 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center justify-center w-full text-xs opacity-50">
                          ({item.countTotal})
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center justify-center w-full">
                          {item.sumTotal}
                        </div>
                      </td>
                      <td className="border-l border-slate-200 dark:border-slate-700 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center justify-center w-full text-xs opacity-50">
                          ({item.countSingle})
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center justify-center w-full">
                          {item.sumSingle}
                        </div>
                      </td>
                      <td className="border-l border-slate-200 dark:border-slate-700 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center justify-center w-full text-xs opacity-50">
                          ({item.countMultible})
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center justify-center w-full">
                          {item.sumMultible}
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default ReportEventDetail;

import React from "react";
import { useConfig } from "../../context/ConfigContext";
import AccountSidebar from "../../components/AccountSidebar";
import Requests from "../../helpers/Requests";
import Flag from "../../components/Flag";

function AccountLanguage() {
  const ConfigProvider = useConfig();
  return (
    <>
      <div className="mb-8">
        {/* Title */}
        <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
          {ConfigProvider.getTranslation("Tab.Account")} ✨
        </h1>
      </div>

      {/* Content */}
      <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm mb-8">
        <div className="flex flex-col md:flex-row md:-mr-px">
          <AccountSidebar />
          <AccountLanguagePage />
        </div>
      </div>
    </>
  );
}

function AccountLanguagePage() {
  const ConfigProvider = useConfig();

  const changeLanguage = async (item) => {
    Requests.postData("/user/setCulture", { lan: item })
      .then(() => {
        sessionStorage.removeItem("translations");
        window.location.reload();
      })
      .catch(() => {
        sessionStorage.removeItem("translations");
        window.location.reload();
      });
  };
  return (
    <div className="grow">
      {/* Panel body */}
      <div className="p-6 space-y-6">
        <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5">
          {ConfigProvider.getTranslation("Language")}
        </h2>

        {/* Language */}
        <section>
          <div className="mt-5">
            <ul>
              {Object.keys(ConfigProvider.config.languages).map(
                (item, index) => (
                  <li
                    key={index}
                    className={`flex items-center py-3 pl-3 border-b border-slate-200 dark:border-slate-700 cursor-pointer ${
                      item === ConfigProvider.config.lan
                        ? "bg-slate-300/10"
                        : ""
                    }`}
                    onClick={() => changeLanguage(item)}
                  >
                    <Flag
                      id={ConfigProvider.config.flagsOfLanguages[item]}
                      className="w-4 h-4 mr-2"
                      svg
                    />
                    {ConfigProvider.config.languages[item]}
                  </li>
                )
              )}
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
}

export default AccountLanguage;

import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { Link, useNavigate } from "react-router-dom";
import {
  addDays,
  checkRole,
  diagnosticResponse,
  formatDate,
  getDate,
} from "../../helpers/Helpers";
import { usePrefences } from "../../context/PrefencesContext";
import Requests from "../../helpers/Requests";
import Alert from "../../components/Alert";
import SearchFilter from "../../components/SearchFilter";
import DropdownCard from "../../components/Search/DropdownCard";
import PaginationClassic from "../../components/PaginationClassic";
import DropdownFilter from "../../components/DropdownFilter";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import NoData from "../../components/NoData";
import SortByItem from "../../components/SortByItems";
import { useToastr } from "../../context/ToastrContext";
import { ArchiveBoxIcon, CalendarIcon } from "@heroicons/react/24/outline";
import ShopTooltip from "../../components/ShopTooltip";

function SettlementComplaints() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "SettlementViewBetComplaints")) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");

  const [formData, setFormData] = useState({
    date1: formatDate(addDays(getDate(), -5), true, false),
    date2: formatDate(addDays(getDate(), 1), true, false),
    card: null,
    status: "-1",
    search: "",
    page: 1,
    sort: 0,
    sortBy: 1,
  });

  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = { ...formData };
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.search = search;
      Requests.postData("/general/getTicketComplaints", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);

  const allStatus = {
    "-1": ConfigProvider.getTranslation("All"),
    0: ConfigProvider.getTranslation("Pending"),
    1: ConfigProvider.getTranslation("Reviewed"),
    2: ConfigProvider.getTranslation("Corrected"),
  };

  return ConfigProvider.config.user !== null ? (
    <>
      <div className="flex space-x-4">
        <div className="relative grow min-w-0">
          {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (formData.page !== 1) {
                setFormData((prev) => {
                  return { ...prev, page: 1 };
                });
              } else {
                getItems();
              }
            }}
            className=""
          >
            {messages.length > 0 && (
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>
            )}
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                  {ConfigProvider.getTranslation("TicketComplaints")} ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Search form */}
                <SearchFilter
                  placeholder={ConfigProvider.getTranslation("BetId")}
                  search={search}
                  setSearch={setSearch}
                />
                <button
                  type="submit"
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                  <span className="ml-2">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                </button>
              </div>
            </div>
            {/* More actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left side */}
              <div className="mb-4 grow flex flex-col lg:flex-row lg:mb-0 gap-4 mr-2">
                <DropdownCard
                  setSelectedItem={(val) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        card: val,
                      };
                    });
                  }}
                />
              </div>
              {/* Right side */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-end gap-2">
                <input
                  id="date1"
                  name="date1"
                  type="datetime-local"
                  value={formData.date1}
                  onChange={(e) => {
                    return setFormData({ ...formData, date1: e.target.value });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("StartDate")}
                  disabled={loading}
                  required
                />
                <input
                  id="date2"
                  name="date2"
                  type="datetime-local"
                  value={formData.date2}
                  onChange={(e) => {
                    return setFormData({ ...formData, date2: e.target.value });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("EndDate")}
                  disabled={loading}
                  required
                />
                <DropdownFilter align="right">
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("Status")}
                  </div>
                  <ul className="mb-4">
                    {Object.keys(allStatus).map((item) => (
                      <li key={item} className="py-1 px-3">
                        <label className="flex items-center cursor-pointer">
                          <input
                            id={item}
                            name={item}
                            type="checkbox"
                            className="form-checkbox"
                            checked={formData.status === item}
                            onChange={() =>
                              setFormData({
                                ...formData,
                                status: item,
                              })
                            }
                            disabled={loading}
                          />
                          <span className="text-sm font-medium ml-2">
                            {allStatus[item]}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </DropdownFilter>
              </div>
            </div>
          </form>

          {/* Table */}
          <TicketsTable
            data={data}
            loading={loading}
            formData={formData}
            setFormData={setFormData}
            getItems={getItems}
            setData={setData}
          />

          {/* Pagination */}
          <div className="mt-8">
            <PaginationClassic
              page={formData.page}
              setPage={(val) => {
                setFormData((prev) => {
                  return { ...prev, page: val };
                });
              }}
              totalItems={totalItems}
            />
          </div>
        </div>
      </div>
    </>
  ) : null;
}

function TicketsTable({
  data,
  setData,
  loading,
  formData,
  setFormData,
  getItems,
}) {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  const ToastrContext = useToastr();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const setTicketComplaints = (_id, stat) => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/general/setTicketComplaints", {
        _id,
        stat,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
            getItems();
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
        });
    }
  };
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("TicketComplaints")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Card")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Date")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={2}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Id")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={3}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("SupportReason")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={4}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Event")}
                    </div>
                  </SortByItem>
                </th>

                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={5}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="font-semibold text-center">
                      {ConfigProvider.getTranslation("Status")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  &nbsp;
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="font-semibold text-center">
                    {ConfigProvider.getTranslation("Account")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="font-semibold text-center">
                    {ConfigProvider.config.isShopSystem
                      ? ConfigProvider.getTranslation("Shop")
                      : ""}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="font-semibold text-center">&nbsp;</div>
                </th>
              </tr>
            </thead>
            {!loading && data && data.length === 0 && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                <tr>
                  <td colSpan="10">
                    <NoData />
                  </td>
                </tr>
              </tbody>
            )}
            {loading && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                <tr>
                  <td colSpan="10" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading &&
              data.map((item) => (
                <tbody key={item._id} className="text-sm">
                  <tr>
                    <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                      <Link
                        to={"/cards/card/view/" + item.aid}
                        className="text-sky-500 dark:text-sky-400 font-medium text-left"
                      >
                        {`${item.uname}${
                          item.name && item.name.length > 0
                            ? ` | ${item.name}`
                            : ""
                        }`}
                      </Link>
                    </td>
                    <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                      <div className="flex items-center justify-center w-full">
                        <CalendarIcon className="w-4 h-4 mr-1" />
                        {new Intl.DateTimeFormat("default", {
                          day: "numeric",
                          month: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          timeZone: PrefencesProvider.prefences.timezone,
                        }).format(new Date(item.date))}
                      </div>
                    </td>
                    <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-slate-800 dark:text-slate-100 text-left">
                        <Link
                          to={`/tickets/${item.cid}`}
                          className="text-purple-500 dark:text-purple-400"
                        >
                          {item.cid}
                        </Link>
                      </div>
                    </td>
                    <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-center">
                        <select
                          className="btn w-full min-w-44 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200"
                          value={item.reason}
                          disabled
                          name="reason"
                        >
                          <option value="2">
                            {ConfigProvider.getTranslation("UnsettledBet")}
                          </option>
                          <option value="1">
                            {ConfigProvider.getTranslation("IncorrectBet")}
                          </option>
                          <option value="0">
                            {ConfigProvider.getTranslation("Other")}
                          </option>
                        </select>
                      </div>
                    </td>
                    <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-left">{item.label}</div>
                    </td>
                    <td
                      colSpan={2}
                      className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap"
                    >
                      <form
                        className="flex space-x-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          setTicketComplaints(item._id, item.stat);
                        }}
                      >
                        <select
                          className="btn w-full min-w-44 bg-white dark:bg-slate-800 border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-500 hover:text-slate-600 dark:text-slate-300 dark:hover:text-slate-200"
                          value={item.stat}
                          name="stat"
                          disabled={item.statOld !== 0}
                          onChange={(e) => {
                            setData((prev) => {
                              const updatedItems = prev.map((subItem) => {
                                if (subItem._id === item._id) {
                                  return {
                                    ...subItem,
                                    stat: e.target.value,
                                  };
                                }
                                return subItem;
                              });
                              return updatedItems;
                            });
                          }}
                        >
                          <option value="0">
                            {ConfigProvider.getTranslation("Pending")}
                          </option>
                          <option value="1">
                            {ConfigProvider.getTranslation("Reviewed")}
                          </option>
                          <option value="2">
                            {ConfigProvider.getTranslation("Corrected")}
                          </option>
                        </select>
                        {item.statOld === 0 && (
                          <button
                            type="submit"
                            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                          >
                            <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                            <span className="ml-2">
                              {ConfigProvider.getTranslation("Button.Save")}
                            </span>
                          </button>
                        )}
                      </form>
                    </td>
                    <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-center">
                        {item.statOld !== 0 &&
                          `${item.accountUname} (${new Intl.DateTimeFormat(
                            "default",
                            {
                              day: "numeric",
                              month: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              timeZone: PrefencesProvider.prefences.timezone,
                            }
                          ).format(new Date(item.date2))})`}
                      </div>
                    </td>

                    <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                      {ConfigProvider.config.isShopSystem && (
                        <ShopTooltip
                          size="lg"
                          position="left"
                          bg="dark"
                          shopName=""
                          shop={item.shop}
                          auth="2"
                        />
                      )}
                    </td>
                    <td className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-center">
                        {checkRole(
                          ConfigProvider.config.user,
                          "ViewLogTicketComplaints"
                        ) ? (
                          <Link
                            to={`/updateLogs/ticketComplaints/${item._id}`}
                            target="_blank"
                            title="Logs"
                            className="ml-2 bg-teal-500 text-white rounded-lg px-2 py-1 text-sm flex items-center"
                          >
                            <ArchiveBoxIcon className="w-5 h-5" />
                          </Link>
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
        </div>
      </div>
    </div>
  );
}

export default SettlementComplaints;

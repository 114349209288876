import React, { useEffect, useState } from "react";
import TreeMain from "../../components/Tree/TreeMain";
import TreeMainNav from "../../components/Tree/TreeMainNav";
import { useConfig } from "../../context/ConfigContext";
import {
  ArrowRightCircleIcon,
  ArrowPathIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/solid";
import Requests from "../../helpers/Requests";
import { usePrefences } from "../../context/PrefencesContext";
import {
  addDays,
  checkRole,
  classNames,
  diagnosticResponse,
  formatDate,
  getDate,
} from "../../helpers/Helpers";
import { Link, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Alert from "../../components/Alert";
import ShopTooltip from "../../components/ShopTooltip";
import { useToastr } from "../../context/ToastrContext";
import PaginationClassic from "../../components/PaginationClassic";
import SortByItem from "../../components/SortByItems";
import NoData from "../../components/NoData";
import DropdownFilter from "../../components/DropdownFilter";
import { useDeviceSize } from "../../context/DeviceSizeContext";
import SearchFilter from "../../components/SearchFilter";
function ListCard() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  const DeviceSizeContext = useDeviceSize();
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "CardsList")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [excel, setExcel] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [formData, setFormData] = useState({
    page: 1,
    stat: 1,
    sort: 3,
    sortBy: 1,
    uname: "",
    btag: "",
    id: "",
    ip: "",
    identity: "",
    name: "",
    sname: "",
    email: "",
    rdate1: "",
    rdate2: "",
    lastLogin1: "",
    lastLogin2: "",
    financeDate1: formatDate(addDays(getDate(), -90)),
    financeDate2: formatDate(addDays(getDate(), 1)),
    phone: "",
    category: "",
  });
  const [cloneFormData] = useState({ ...formData });
  const resetFilters = () => {
    const updatedFormData = { ...formData };
    Object.keys(cloneFormData)
      .filter((x) => !["page", "stat", "sort", "sortBy"].includes(x))
      .forEach((key) => {
        updatedFormData[key] = cloneFormData[key];
      });

    setFormData(updatedFormData);
  };
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = { ...formData };
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.excel = excel;
      Requests.postData("/cards/getCards", postData, excel)
        .then((response) => {
          let res = response.data;
          diagnosticResponse(res);

          if (excel) {
            if (
              response.headers["content-type"] ===
              "application/json; charset=utf-8"
            ) {
              Requests.postData("/cards/getCards", postData, false).then(
                (response) => {
                  let res = response.data;
                  diagnosticResponse(res);
                  if (res.type !== "success") {
                    setMessages([
                      {
                        type: "error",
                        text: res.message.length
                          ? res.message
                          : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                      },
                    ]);
                    document.getElementById("mainBody").scrollTo(0, 0);
                  }
                }
              );
            } else {
              const blob = new Blob([response.data], {
                type: "application/vnd.ms-excel",
              });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = url;
              a.download = "export.xlsx";
              document.body.appendChild(a);
              a.click();
              a.remove();
            }
          } else {
            if (res.type !== "success") {
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
              document.getElementById("mainBody").scrollTo(0, 0);
            } else {
              if (formData.page === 1) {
                setTotalItems(res.c);
              }
              setData(res.data);
            }
          }
          setLoading(false);
          setExcel(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
          setExcel(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.stat,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);
  useEffect(() => {
    if (excel) {
      getItems();
    } // eslint-disable-next-line
  }, [excel]);

  const [allCategories, setAllCategories] = useState({});
  useEffect(() => {
    Requests.postData("/cards/getCategoriesSelect", {})
      .then((response) => {
        const res = response.data;
        res.data[""] = ConfigProvider.getTranslation("Filter.All");
        res.type === "success" && setAllCategories(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
    // eslint-disable-next-line
  }, []);
  return ConfigProvider.config.user !== null ? (
    <>
      <div className="flex space-x-4">
        {ConfigProvider.config.isShopSystem &&
        (ConfigProvider.config.user.auth === "0") |
          (ConfigProvider.config.user.auth === "1") ? (
          <TreeMain
            callback={() => {
              getItems();
            }}
          />
        ) : null}
        <div className="relative grow min-w-0">
          {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (formData.page !== 1) {
                setFormData((prev) => {
                  return { ...prev, page: 1 };
                });
              } else {
                getItems();
              }
            }}
            className=""
          >
            {messages.length > 0 && (
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>
            )}
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                  {ConfigProvider.getTranslation("Cards")} ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                <SearchFilter
                  placeholder={ConfigProvider.getTranslation("Username")}
                  search={formData.uname}
                  setSearch={(val) => {
                    setFormData({ ...formData, uname: val });
                  }}
                />
                <DropdownFilter
                  align={
                    DeviceSizeContext.deviceType === "lg" ? "right" : undefined
                  }
                >
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    <button
                      type="button"
                      className="btn bg-indigo-500 hover:bg-indigo-600 text-white w-full"
                      onClick={() => {
                        resetFilters();
                      }}
                    >
                      <ArrowPathIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                      <span className="ml-2">
                        {ConfigProvider.getTranslation("Reset")}
                      </span>
                    </button>
                  </div>

                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("ID")}
                  </div>
                  <ul className="mb-4">
                    <li className="py-1 px-3">
                      <input
                        name="id"
                        type="number"
                        className="form-input w-full bg-white dark:bg-slate-800"
                        value={formData.id}
                        onChange={handleChange}
                        placeholder=""
                      />
                    </li>
                  </ul>
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("Name")}
                  </div>
                  <ul className="mb-4">
                    <li className="py-1 px-3">
                      <input
                        name="name"
                        type="text"
                        className="form-input w-full bg-white dark:bg-slate-800"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder={ConfigProvider.getTranslation("Name")}
                        maxLength={50}
                      />
                    </li>
                  </ul>
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("Surname")}
                  </div>
                  <ul className="mb-4">
                    <li className="py-1 px-3">
                      <input
                        name="sname"
                        type="text"
                        className="form-input w-full bg-white dark:bg-slate-800"
                        value={formData.sname}
                        onChange={handleChange}
                        placeholder={ConfigProvider.getTranslation("Surname")}
                        maxLength={50}
                      />
                    </li>
                  </ul>
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("Email")}
                  </div>
                  <ul className="mb-4">
                    <li className="py-1 px-3">
                      <input
                        name="email"
                        type="email"
                        className="form-input w-full bg-white dark:bg-slate-800"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email"
                      />
                    </li>
                  </ul>
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("Phone")}
                  </div>
                  <ul className="mb-4">
                    <li className="py-1 px-3">
                      <input
                        name="phone"
                        type="text"
                        className="form-input w-full bg-white dark:bg-slate-800"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder={ConfigProvider.getTranslation("Phone")}
                      />
                    </li>
                  </ul>
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("Btag")}
                  </div>
                  <ul className="mb-4">
                    <li className="py-1 px-3">
                      <input
                        name="btag"
                        type="text"
                        className="form-input w-full bg-white dark:bg-slate-800"
                        value={formData.btag}
                        onChange={handleChange}
                        placeholder="Btag"
                      />
                    </li>
                  </ul>
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("RegisterDate.Mini")}
                  </div>
                  <ul className="mb-4">
                    <li className="py-1 px-3">
                      <input
                        name="rdate1"
                        type="datetime-local"
                        value={formData.rdate1}
                        onChange={handleChange}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation("StartDate")}
                        disabled={loading}
                      />
                    </li>
                    <li className="py-1 px-3">
                      <input
                        name="rdate2"
                        type="datetime-local"
                        value={formData.rdate2}
                        onChange={handleChange}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation("EndDate")}
                        disabled={loading}
                      />
                    </li>
                  </ul>
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("LastLogin")}
                  </div>
                  <ul className="mb-4">
                    <li className="py-1 px-3">
                      <input
                        name="lastLogin1"
                        type="datetime-local"
                        value={formData.lastLogin1}
                        onChange={handleChange}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation("StartDate")}
                        disabled={loading}
                      />
                    </li>
                    <li className="py-1 px-3">
                      <input
                        name="lastLogin2"
                        type="datetime-local"
                        value={formData.lastLogin2}
                        onChange={handleChange}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation("EndDate")}
                        disabled={loading}
                      />
                    </li>
                  </ul>
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("Identity")}
                  </div>
                  <ul className="mb-4">
                    <li className="py-1 px-3">
                      <input
                        name="identity"
                        type="text"
                        className="form-input w-full bg-white dark:bg-slate-800"
                        value={formData.identity}
                        onChange={handleChange}
                        placeholder={ConfigProvider.getTranslation("Identity")}
                      />
                    </li>
                  </ul>
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("IP")}
                  </div>
                  <ul className="mb-4">
                    <li className="py-1 px-3">
                      <input
                        name="ip"
                        type="text"
                        className="form-input w-full bg-white dark:bg-slate-800"
                        value={formData.ip}
                        onChange={handleChange}
                        placeholder={ConfigProvider.getTranslation("Ip")}
                      />
                    </li>
                  </ul>
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("FinancialTransactions")}
                  </div>
                  <ul className="mb-4">
                    <li className="py-1 px-3">
                      <input
                        name="financeDate1"
                        type="date"
                        value={formData.financeDate1}
                        onChange={handleChange}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation("StartDate")}
                        disabled={loading}
                      />
                    </li>
                    <li className="py-1 px-3">
                      <input
                        name="financeDate2"
                        type="date"
                        value={formData.financeDate2}
                        onChange={handleChange}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation("EndDate")}
                        disabled={loading}
                      />
                    </li>
                  </ul>

                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("Category")}
                  </div>
                  <ul className="mb-4">
                    {Object.keys(allCategories).map((item) => (
                      <li key={item} className="py-1 px-3">
                        <label className="flex items-center cursor-pointer">
                          <input
                            id={item}
                            name={item}
                            type="checkbox"
                            className="form-checkbox"
                            checked={formData.category === item}
                            onChange={() =>
                              setFormData({
                                ...formData,
                                category: item,
                              })
                            }
                            disabled={loading}
                          />
                          <span className="text-sm font-medium ml-2">
                            {allCategories[item]}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    <button
                      type="button"
                      className="btn bg-indigo-500 hover:bg-indigo-600 text-white w-full"
                      onClick={() => {
                        resetFilters();
                      }}
                    >
                      <ArrowPathIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                      <span className="ml-2">
                        {ConfigProvider.getTranslation("Reset")}
                      </span>
                    </button>
                  </div>
                </DropdownFilter>

                <button
                  type="submit"
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                  <span className="ml-2">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                </button>
                {checkRole(ConfigProvider.config.user, "CardsExportExcel") && (
                  <button
                    type="button"
                    onClick={() => {
                      setExcel(true);
                    }}
                    className="btn bg-emerald-500 hover:bg-emerald-600 text-slate-800"
                  >
                    <DocumentTextIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                    <span className="ml-2">
                      {ConfigProvider.getTranslation("Excel")}
                    </span>
                  </button>
                )}
              </div>
            </div>
            {/* More actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left side */}
              <div className="mb-4 sm:mb-0">
                <ul className="flex flex-wrap">
                  <li className="m-1">
                    <button
                      type="button"
                      className={classNames(
                        "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border shadow-sm duration-150 ease-in-out",
                        formData.stat === -1
                          ? "border-transparent bg-indigo-500 text-white"
                          : "border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 "
                      )}
                      onClick={() => {
                        setFormData({ ...formData, stat: -1 });
                      }}
                    >
                      {ConfigProvider.getTranslation("Filter.All")}
                    </button>
                  </li>
                  <li className="m-1">
                    <button
                      type="button"
                      className={classNames(
                        "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border shadow-sm duration-150 ease-in-out",
                        formData.stat === 1
                          ? "border-transparent bg-indigo-500 text-white"
                          : "border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 "
                      )}
                      onClick={() => {
                        setFormData({ ...formData, stat: 1 });
                      }}
                    >
                      {ConfigProvider.getTranslation("Active")}
                    </button>
                  </li>
                  <li className="m-1">
                    <button
                      type="button"
                      className={classNames(
                        "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border shadow-sm duration-150 ease-in-out",
                        formData.stat === 0
                          ? "border-transparent bg-indigo-500 text-white"
                          : "border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 "
                      )}
                      onClick={() => {
                        setFormData({ ...formData, stat: 0 });
                      }}
                    >
                      {ConfigProvider.getTranslation("Passive")}
                    </button>
                  </li>
                  <li className="m-1">
                    <button
                      type="button"
                      className={classNames(
                        "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border shadow-sm duration-150 ease-in-out",
                        formData.stat === 3
                          ? "border-transparent bg-indigo-500 text-white"
                          : "border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 "
                      )}
                      onClick={() => {
                        setFormData({ ...formData, stat: 3 });
                      }}
                    >
                      {ConfigProvider.getTranslation("SelfExcluded")}
                    </button>
                  </li>
                </ul>
              </div>
              {/* Right side */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-end gap-2">
                {/* Add button */}
                {checkRole(ConfigProvider.config.user, "CardsAdd") && (
                  <Link
                    to="/cards/add"
                    className="btn bg-green-500 hover:bg-green-600 text-slate-100"
                  >
                    <svg
                      className="w-4 h-4 fill-slate-100 shrink-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="hidden xs:block ml-2">
                      {ConfigProvider.getTranslation("Add")}
                    </span>
                  </Link>
                )}
              </div>
            </div>

            {/* Table */}
            <CardTable
              data={data}
              loading={loading}
              formData={formData}
              setFormData={setFormData}
            />

            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                page={formData.page}
                setPage={(val) => {
                  setFormData((prev) => {
                    return { ...prev, page: val };
                  });
                }}
                totalItems={totalItems}
              />
            </div>
          </form>
        </div>
      </div>
      {ConfigProvider.config.isShopSystem &&
      (ConfigProvider.config.user.auth === "0") |
        (ConfigProvider.config.user.auth === "1") ? (
        <TreeMainNav
          callback={() => {
            getItems();
          }}
        />
      ) : null}
    </>
  ) : null;
}

function CardTable({ data, loading, formData, setFormData }) {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  const ToastrProvider = useToastr();
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("Cards")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="font-semibold text-left">ID</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={0}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Username")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Name")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={2}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Credits")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("E-mail")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Phone")}
                  </div>
                </th>

                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={3}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="font-semibold text-center">
                      {ConfigProvider.getTranslation("RegisterDate.Mini")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={4}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="font-semibold text-center">
                      {ConfigProvider.getTranslation("LastLogin")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">
                    {ConfigProvider.getTranslation("Btag")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">
                    {ConfigProvider.getTranslation("Category")}
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={5}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="font-semibold text-center">
                      {ConfigProvider.getTranslation("Status")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">&nbsp;</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              {!loading && data && data.length === 0 && (
                <tr>
                  <td colSpan="12">
                    <NoData />
                  </td>
                </tr>
              )}
              {loading ? (
                <tr>
                  <td colSpan="12" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              ) : (
                data.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        <div>
                          <Link
                            onClick={() => {
                              navigator.clipboard
                                .writeText(item.id)
                                .then(() => {
                                  ToastrProvider.showToast({
                                    type: "success",
                                    text: ConfigProvider.getTranslation(
                                      "CopiedSuccessfull"
                                    ),
                                  });
                                })
                                .catch(() => {
                                  ToastrProvider.showToast({
                                    type: "error",
                                    text: ConfigProvider.getTranslation(
                                      "Forms.ErrorMessage"
                                    ),
                                  });
                                });
                            }}
                          >
                            #{item.id}
                          </Link>
                        </div>
                        {item.secondaryId && item.secondaryId.length > 0 ? (
                          <div className="text-xs text-slate-500 dark:text-slate-400">
                            <Link
                              onClick={() => {
                                navigator.clipboard
                                  .writeText(item.secondaryId)
                                  .then(() => {
                                    ToastrProvider.showToast({
                                      type: "success",
                                      text: ConfigProvider.getTranslation(
                                        "CopiedSuccessfull"
                                      ),
                                    });
                                  })
                                  .catch(() => {
                                    ToastrProvider.showToast({
                                      type: "error",
                                      text: ConfigProvider.getTranslation(
                                        "Forms.ErrorMessage"
                                      ),
                                    });
                                  });
                              }}
                            >
                              {item.secondaryId}
                            </Link>
                          </div>
                        ) : null}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-full">
                        <div className="font-medium text-slate-800 dark:text-slate-100 text-left">
                          <Link
                            className="text-sky-500 dark:text-sky-400"
                            to={"/cards/card/view/" + item._id}
                          >
                            {item.uname}
                          </Link>
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        <div className="text-center">
                          {(item.name ?? "") + " " + (item.sname ?? "")}
                        </div>
                      </td>
                      <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div
                          className={classNames(
                            "text-center",
                            item.balance > 0
                              ? "text-emerald-500"
                              : item.balance === 0
                              ? "text-slate-500"
                              : "text-rose-500"
                          )}
                        >
                          {`${item.strbalance} ${ConfigProvider.config.user.curSession}`}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        <div className="text-center">{item.email}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        <div className="text-center">{item.phone}</div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        <div className="text-center">
                          {new Intl.DateTimeFormat("default", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            timeZone: PrefencesProvider.prefences.timezone,
                          }).format(new Date(item.rdate))}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        <div className="text-center">
                          {new Intl.DateTimeFormat("default", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            timeZone: PrefencesProvider.prefences.timezone,
                          }).format(new Date(item.lastLogin))}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        <div className="text-center">{item.btag}</div>
                      </td>
                      <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        <div
                          className={classNames(
                            "inline-flex font-medium rounded-full text-center px-2.5 py-0.5",
                            "bg-amber-100 dark:bg-amber-400/30 text-amber-600 dark:text-amber-400"
                          )}
                        >
                          {item.category}
                        </div>
                      </td>
                      <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        <div
                          className={classNames(
                            "inline-flex font-medium rounded-full text-center px-2.5 py-0.5",
                            item.stat === 1
                              ? "bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400"
                              : item.stat === 0
                              ? "bg-rose-100 dark:bg-rose-500/30 text-rose-500 dark:text-rose-400"
                              : "bg-slate-100 dark:bg-slate-800 text-slate-500 dark:text-slate-400"
                          )}
                        >
                          {item.stat === 1
                            ? ConfigProvider.getTranslation("Active")
                            : item.stat === 3
                            ? ConfigProvider.getTranslation("SelfExcluded")
                            : ConfigProvider.getTranslation("Passive")}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        {ConfigProvider.config.isShopSystem && (
                          <ShopTooltip
                            size="lg"
                            position="left"
                            bg="dark"
                            shopName={item.shopName}
                            shop={item.shop}
                            auth="2"
                          />
                        )}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ListCard;

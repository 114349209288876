import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import { WelcomeBanner } from "../../components/WelcomeBanner";
import Alert from "../../components/Alert";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import {
  ArchiveBoxIcon,
  ArrowRightCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useToastr } from "../../context/ToastrContext";
import Confirm from "../../components/Confirm";
import Dropdown from "../../components/Dropdown";
import LocationDropdown from "../../components/LocationDropdown";

function UpsertPageConfig() {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const { _id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "CMSManagePageConfigurator")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    type: null,
    className: "",
    order: 1,
    active: true,
    combine: false,
    config: {},
    _id: _id ? _id : undefined,
  });
  const [messages, setMessages] = useState([]);
  const submitForm = (e) => {
    e.preventDefault();
    if (!loading) {
      setMessages([]);
      let requiredFieldErros = [];
      if (!formData.location) {
        requiredFieldErros.push(ConfigProvider.getTranslation("Location"));
      }
      if (!formData.type) {
        requiredFieldErros.push(ConfigProvider.getTranslation("Type"));
      } else {
        if (formData.type === "0") {
          let name = "location";
          if (
            !(formData.config.hasOwnProperty(name) && formData.config[name])
          ) {
            requiredFieldErros.push(ConfigProvider.getTranslation("Location"));
          }
        } else if (formData.type === "1") {
          let name = "type";
          if (!formData.config.hasOwnProperty(name)) {
            requiredFieldErros.push(ConfigProvider.getTranslation("Type"));
          }
        } else if (formData.type === "2") {
          [
            { name: "type", translate: "Type" },
            { name: "transaction", translate: "transaction" },
            { name: "period", translate: "Period" },
          ].forEach((item) => {
            if (!formData.config.hasOwnProperty(item.name)) {
              requiredFieldErros.push(
                ConfigProvider.getTranslation(item.translate)
              );
            }
          });
        } else if (formData.type === "3") {
          let name = "category";
          if (!formData.config.hasOwnProperty(name)) {
            requiredFieldErros.push(ConfigProvider.getTranslation("Category"));
          }
        } else if (formData.type === "6") {
          let name = "id";
          if (!formData.config.hasOwnProperty(name)) {
            requiredFieldErros.push(
              ConfigProvider.getTranslation("HTMLComponent")
            );
          }
        } else if (formData.type === "7") {
          let name = "type";
          if (!formData.config.hasOwnProperty(name)) {
            requiredFieldErros.push(ConfigProvider.getTranslation("Type"));
          }
        }
      }
      if (requiredFieldErros.length > 0) {
        setMessages([
          {
            type: "error",
            text:
              "[" +
              requiredFieldErros.join(",") +
              "] " +
              ConfigProvider.getTranslation("Forms.EmptyValueError"),
          },
        ]);
        document.getElementById("mainBody").scrollTo(0, 0);
      } else {
        setLoading(true);
        let postData = formData;
        Requests.postData("/cms/updatePageItem", postData)
          .then((response) => {
            let res = response.data;
            if (res.type !== "success") {
              diagnosticResponse(res);
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
              document.getElementById("mainBody").scrollTo(0, 0);
            } else {
              setMessages([
                {
                  type: "success",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.SuccessMessage"),
                },
              ]);
              setTimeout(() => {
                navigate(`/cms/pageConfigurator`);
              }, 1000);
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [showConfirm, setShowConfirm] = useState(false);
  const deleteItem = () => {
    if (formData._id && !loading) {
      setLoading(true);
      Requests.postData("/cms/deletePageItem", {
        _id: formData._id,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });

            setShowConfirm(false);
            navigate("/cms/pageConfigurator");
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setLoading(false);
          setShowConfirm(false);
        });
    }
  };
  const [allLocations, setAllLocations] = useState(null);
  const getPageLocations = () => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/cms/getPageLocations", {})
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (Object.keys(res.data).length > 0) {
              setAllLocations(res.data);
            }
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getPageLocations();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (formData._id && allLocations) {
      Requests.postData("/cms/getPageItem", {
        _id: formData._id,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setFormData((prev) => {
              return {
                ...prev,
                name: res.data.name,
                location: res.data.location,
                type: res.data.type,
                className: res.data.className,
                order: res.data.order,
                active: res.data.active,
                combine: res.data.combine,
                config: res.data.config,
              };
            });
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [formData._id, allLocations]);

  const allTypes = {
    0: ConfigProvider.getTranslation("Slider"),
    1: ConfigProvider.getTranslation("Jackpot"),
    2: ConfigProvider.getTranslation("WinnersModule"),
    3: ConfigProvider.getTranslation("TopCasinoGames"),
    4: ConfigProvider.getTranslation("Brands"),
    5: ConfigProvider.getTranslation("Content"),
    6: ConfigProvider.getTranslation("Html"),
    7: ConfigProvider.getTranslation("LastWinnersModule"),
    8: ConfigProvider.getTranslation("CardsComponent"),
    9: ConfigProvider.getTranslation("Marquee"),
  };
  return (
    <div className="grow mx-auto">
      <WelcomeBanner>
        {ConfigProvider.getTranslation("PageConfigurator")}
        {" / "}
        {formData._id
          ? ConfigProvider.getTranslation("Edit")
          : ConfigProvider.getTranslation("Add")}
        💫
      </WelcomeBanner>
      <form className="w-full" autoComplete="off" onSubmit={submitForm}>
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
            {ConfigProvider.getTranslation("General")}
          </h2>
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>

          <section>
            <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="name"
                >
                  {ConfigProvider.getTranslation("Name")}
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("Name")}
                  maxLength={50}
                  disabled={loading}
                  required
                />
              </div>
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="className"
                >
                  {ConfigProvider.getTranslation("ClassName")}
                </label>
                <input
                  id="className"
                  name="className"
                  type="text"
                  value={formData.className}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("ClassName")}
                  disabled={loading}
                />
              </div>
              {allLocations && (
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="location"
                  >
                    {ConfigProvider.getTranslation("Location")}
                  </label>
                  <Dropdown
                    name="type"
                    items={allLocations}
                    selected={formData.location}
                    setSelected={(val) => {
                      setFormData({ ...formData, location: val });
                    }}
                  />
                </div>
              )}
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="type"
                >
                  {ConfigProvider.getTranslation("Type")}
                </label>
                <Dropdown
                  name="type"
                  items={allTypes}
                  topItems={{
                    "": ConfigProvider.getTranslation("Drowndown.SelectOne"),
                  }}
                  selected={formData.type}
                  setSelected={(val) => {
                    setFormData({ ...formData, type: val, config: {} });
                  }}
                />
              </div>
              <div className="relative col-span-2">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="order"
                >
                  {ConfigProvider.getTranslation("SortOrder")}
                </label>
                <input
                  id="order"
                  name="order"
                  type="number"
                  value={formData.order}
                  onChange={handleChange}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("SortOrder")}
                  required
                  maxLength={10}
                  disabled={loading}
                  step={1}
                  pattern="\d*"
                />
              </div>
              <div className="relative">
                <div className="flex items-center my-2">
                  <div className="form-switch">
                    <input
                      id="combine"
                      name="combine"
                      type="checkbox"
                      className="sr-only"
                      checked={formData.combine}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          combine: !formData.combine,
                        })
                      }
                      disabled={loading}
                    />
                    <label
                      className="bg-slate-400 dark:bg-slate-700"
                      htmlFor="combine"
                    >
                      <span
                        className="bg-white shadow-sm"
                        aria-hidden="true"
                      ></span>
                    </label>
                  </div>
                  <div className="text-sm text-slate-700 dark:text-slate-400  ml-2">
                    {ConfigProvider.getTranslation("Combine")}
                  </div>
                </div>
              </div>
              <div className="relative">
                <div className="flex items-center my-2">
                  <div className="form-switch">
                    <input
                      id="active"
                      name="active"
                      type="checkbox"
                      className="sr-only"
                      checked={formData.active}
                      onChange={() =>
                        setFormData({
                          ...formData,
                          active: !formData.active,
                        })
                      }
                      disabled={loading}
                    />
                    <label
                      className="bg-slate-400 dark:bg-slate-700"
                      htmlFor="active"
                    >
                      <span
                        className="bg-white shadow-sm"
                        aria-hidden="true"
                      ></span>
                    </label>
                  </div>
                  <div className="text-sm text-slate-700 dark:text-slate-400  ml-2">
                    {formData.active
                      ? ConfigProvider.getTranslation("Active")
                      : ConfigProvider.getTranslation("Passive")}
                  </div>
                </div>
              </div>
              <UpsertPageConfigType
                loading={loading}
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </section>
        </div>
        {/* Panel footer */}
        <footer>
          <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
            <div className="flex justify-between">
              {checkRole(
                ConfigProvider.config.user,
                "CMSManagePageConfigurator"
              ) & formData._id ? (
                <button
                  type="button"
                  disabled={loading}
                  className="btn bg-rose-500 hover:bg-rose-600 text-white ml-3 flex items-center"
                  onClick={() => {
                    setShowConfirm(true);
                  }}
                >
                  {loading ? (
                    <span>
                      <LoadingSpinner className="w-4 h-4 mb-1" />
                    </span>
                  ) : (
                    <span>
                      <XMarkIcon className="w-4 h-4" />
                    </span>
                  )}
                  <span className="ml-2 uppercase">
                    {ConfigProvider.getTranslation("Delete")}
                  </span>
                </button>
              ) : (
                <>&nbsp;</>
              )}
              {formData._id &&
              checkRole(ConfigProvider.config.user, "ViewLogPageItems") ? (
                <Link
                  to={`/updateLogs/pageItems/${formData._id}`}
                  target="_blank"
                  title="Logs"
                  className="ml-2 bg-teal-500 text-white rounded-lg px-2 py-1 text-sm flex items-center"
                >
                  <ArchiveBoxIcon className="w-5 h-5" />
                </Link>
              ) : (
                <></>
              )}
              <button
                type="submit"
                disabled={loading}
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
              >
                <span className="mr-2 uppercase">
                  {ConfigProvider.getTranslation("Button.Send")}
                </span>
                {loading ? (
                  <span>
                    <LoadingSpinner className="w-4 h-4 mb-1" />
                  </span>
                ) : (
                  <span>
                    <ArrowRightCircleIcon className="w-4 h-4" />
                  </span>
                )}
              </button>
            </div>
          </div>
        </footer>
      </form>
      {showConfirm && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{ConfigProvider.getTranslation("ToContinuePleaseConfirm")}</p>
            </>
          }
          confirm={() => {
            deleteItem();
            setShowConfirm(false);
          }}
          callback={() => {
            setShowConfirm(false);
          }}
        />
      )}
    </div>
  );
}
function UpsertPageConfigType({ formData, setFormData, loading }) {
  return (
    <>
      {formData.type === "0" ? (
        <SliderModule
          formData={formData}
          setFormData={setFormData}
          loading={loading}
        />
      ) : formData.type === "1" ? (
        <JackpotModule
          formData={formData}
          setFormData={setFormData}
          loading={loading}
        />
      ) : formData.type === "2" ? (
        <WinnersModule
          formData={formData}
          setFormData={setFormData}
          loading={loading}
        />
      ) : formData.type === "3" ? (
        <TopCasinoGamesModule formData={formData} setFormData={setFormData} />
      ) : formData.type === "6" ? (
        <HTMLModule
          formData={formData}
          setFormData={setFormData}
          loading={loading}
        />
      ) : formData.type === "7" ? (
        <LastWinnersModule
          formData={formData}
          setFormData={setFormData}
          loading={loading}
        />
      ) : null}
    </>
  );
}
function SliderModule({ formData, loading, setFormData }) {
  const ConfigProvider = useConfig();

  return (
    <>
      <div className="relative col-span-2">
        <label className="block text-sm font-medium mb-1" htmlFor="locations">
          {ConfigProvider.getTranslation("Location")}
        </label>
        <LocationDropdown
          name="locations"
          selected={formData.config.location}
          setSelectedItem={(val) => {
            !loading &&
              setFormData((prev) => {
                return { ...prev, config: { ...prev.config, location: val } };
              });
          }}
        />
      </div>
    </>
  );
}
function JackpotModule({ formData, loading, setFormData }) {
  const ConfigProvider = useConfig();
  const allTypes = { all: "all", sports: "sports", casino: "casino" };
  return (
    <>
      <div className="relative col-span-2">
        <label className="block text-sm font-medium mb-1" htmlFor="lan">
          {ConfigProvider.getTranslation("Type")}
        </label>
        <Dropdown
          name="type"
          items={allTypes}
          selected={formData.config.type}
          setSelected={(val) => {
            !loading &&
              setFormData((prev) => {
                return { ...prev, config: { ...prev.config, type: val } };
              });
          }}
          align="top"
        />
      </div>
    </>
  );
}
function WinnersModule({ formData, loading, setFormData }) {
  const ConfigProvider = useConfig();
  // type, period, transaction
  const allTypes = { topWinners: "topWinners", lastWinners: "lastWinners" };
  const allPeriods = {
    daily: "daily",
    weekly: "weekly",
    monthly: "monthly",
    allTimes: "allTimes",
  };
  const allTransactions = {
    sport: "sport",
    casino: "casino",
  };
  return (
    <>
      <div className="relative">
        <label className="block text-sm font-medium mb-1" htmlFor="type">
          {ConfigProvider.getTranslation("Type")}
        </label>
        <Dropdown
          name="type"
          items={allTypes}
          selected={formData.config.type}
          setSelected={(val) => {
            !loading &&
              setFormData((prev) => {
                return { ...prev, config: { ...prev.config, type: val } };
              });
          }}
          align="top"
        />
      </div>
      <div className="relative">
        <label className="block text-sm font-medium mb-1" htmlFor="period">
          {ConfigProvider.getTranslation("Period")}
        </label>
        <Dropdown
          name="period"
          items={allPeriods}
          selected={formData.config.period}
          setSelected={(val) => {
            !loading &&
              setFormData((prev) => {
                return { ...prev, config: { ...prev.config, period: val } };
              });
          }}
          align="top"
        />
      </div>
      <div className="relative">
        <label className="block text-sm font-medium mb-1" htmlFor="transaction">
          {ConfigProvider.getTranslation("Transaction")}
        </label>
        <Dropdown
          name="transaction"
          items={allTransactions}
          selected={formData.config.transaction}
          setSelected={(val) => {
            !loading &&
              setFormData((prev) => {
                return {
                  ...prev,
                  config: { ...prev.config, transaction: val },
                };
              });
          }}
          align="top"
        />
      </div>
    </>
  );
}

function HTMLModule({ formData, setFormData }) {
  const ConfigProvider = useConfig();
  const [HTMLComponents, setHTMLComponents] = useState(null);
  const getHtmlComponentFilters = () => {
    Requests.postData("/cms/getHtmlComponentFilters", {})
      .then((response) => {
        let res = response.data;
        if (diagnosticResponse(res) && res.type === "success") {
          setHTMLComponents(res.data);
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    getHtmlComponentFilters();
  }, []);

  return (
    <>
      {HTMLComponents && (
        <div className="relative grow">
          <label className="block text-sm font-medium mb-1" htmlFor="category">
            {ConfigProvider.getTranslation("HTMLComponent")}
          </label>
          <Dropdown
            name="id"
            items={HTMLComponents}
            selected={formData.config.id}
            setSelected={(val) => {
              setFormData({
                ...formData,
                config: { ...formData.config, id: val },
              });
            }}
            align="top"
          />
        </div>
      )}
    </>
  );
}
function TopCasinoGamesModule({ formData, setFormData }) {
  const ConfigProvider = useConfig();
  const [casinoCategories, setCasinoCategories] = useState(null);
  const getProviders = () => {
    Requests.postData("/casino/getCasinoGamesFilters", {})
      .then((response) => {
        let res = response.data;
        if (diagnosticResponse(res) && res.type === "success") {
          setCasinoCategories(res.data.casinoCategories);
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    getProviders();
  }, []);

  return (
    <>
      {casinoCategories && (
        <div className="relative grow">
          <label className="block text-sm font-medium mb-1" htmlFor="category">
            {ConfigProvider.getTranslation("Category")}
          </label>
          <Dropdown
            name="category"
            items={casinoCategories}
            selected={formData.config.category}
            setSelected={(val) => {
              setFormData({
                ...formData,
                config: { ...formData.config, category: val },
              });
            }}
            align="top"
          />
        </div>
      )}
    </>
  );
}
function LastWinnersModule({ formData, loading, setFormData }) {
  const ConfigProvider = useConfig();
  const allTypes = { casino: "casino" };
  return (
    <>
      <div className="relative col-span-2">
        <label className="block text-sm font-medium mb-1" htmlFor="lan">
          {ConfigProvider.getTranslation("Type")}
        </label>
        <Dropdown
          name="type"
          items={allTypes}
          selected={formData.config.type}
          setSelected={(val) => {
            !loading &&
              setFormData((prev) => {
                return { ...prev, config: { ...prev.config, type: val } };
              });
          }}
          align="top"
        />
      </div>
    </>
  );
}
export default UpsertPageConfig;

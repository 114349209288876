import React, { useEffect, useState } from "react";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";

import { useConfig } from "../../context/ConfigContext";
import { usePrefences } from "../../context/PrefencesContext";
import { Link, useNavigate } from "react-router-dom";
import {
  checkRole,
  classNames,
  diagnosticResponse,
} from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import Alert from "../../components/Alert";
import SearchFilter from "../../components/SearchFilter";
import PaginationClassic from "../../components/PaginationClassic";
import DropdownFilter from "../../components/DropdownFilter";
import SortByItem from "../../components/SortByItems";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

function HomePageCardComponents() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (
      !checkRole(ConfigProvider.config.user, "CMSManageHomePageCardComponents")
    ) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    page: 1,
    sort: 0,
    sortBy: 0,
    stat: "-1",
  });
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = { ...formData };
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.search = search;
      Requests.postData("/cms/getHomePageCardComponents", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);

  const allStat = {
    "-1": ConfigProvider.getTranslation("Filter.All"),
    1: ConfigProvider.getTranslation("Active"),
    0: ConfigProvider.getTranslation("Passive"),
  };

  return (
    <>
      {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (formData.page !== 1) {
            setFormData((prev) => {
              return { ...prev, page: 1 };
            });
          } else {
            getItems();
          }
        }}
        className=""
      >
        {messages.length > 0 && (
          <div className="mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
        )}
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-5">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
              {ConfigProvider.getTranslation("HomePageCardComponents")} ✨
            </h1>
          </div>

          {/* Right: Actions */}
          <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
            <Link
              to="/cms/homePageCardComponent/add"
              className="btn bg-green-500 hover:bg-green-600 text-slate-100"
            >
              <svg
                className="w-4 h-4 fill-slate-100 shrink-0"
                viewBox="0 0 16 16"
              >
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
              </svg>
              <span className="hidden xs:block ml-2">
                {ConfigProvider.getTranslation("Add")}
              </span>
            </Link>
          </div>
        </div>
        {/* More actions */}
        <div className="lg:flex lg:justify-between lg:items-center mb-5">
          {/* Left side */}
          <div className="mb-4 lg:mb-0"></div>
          {/* Right side */}
          <div className="flex flex-col lg:grid lg:grid-flow-col lg:auto-cols-max justify-end gap-2">
            {/* Search form */}
            <SearchFilter
              placeholder={ConfigProvider.getTranslation("Search")}
              search={search}
              setSearch={setSearch}
            />
            <DropdownFilter align="right">
              <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                {ConfigProvider.getTranslation("Status")}
              </div>
              <ul className="mb-4">
                {Object.keys(allStat).map((item) => (
                  <li key={item} className="py-1 px-3">
                    <label className="flex items-center cursor-pointer">
                      <input
                        id={item}
                        name={item}
                        type="checkbox"
                        className="form-checkbox"
                        checked={formData.stat === item}
                        onChange={() =>
                          setFormData({
                            ...formData,
                            stat: item,
                          })
                        }
                        disabled={loading}
                      />
                      <span className="text-sm font-medium ml-2">
                        {allStat[item]}
                      </span>
                    </label>
                  </li>
                ))}
              </ul>
            </DropdownFilter>
            <button
              type="submit"
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
              <span className="ml-2">
                {ConfigProvider.getTranslation("Button.Send")}
              </span>
            </button>
          </div>
        </div>

        {/* Table */}
        <HomePageCardComponentsTable
          data={data}
          loading={loading}
          formData={formData}
          setFormData={setFormData}
        />

        {/* Pagination */}
        <div className="mt-8">
          <PaginationClassic
            page={formData.page}
            setPage={(val) => {
              setFormData((prev) => {
                return { ...prev, page: val };
              });
            }}
            totalItems={totalItems}
          />
        </div>
      </form>
    </>
  );
}
function HomePageCardComponentsTable({ data, loading, formData, setFormData }) {
  const ConfigProvider = useConfig();
  const cdnEndpoint = process.env.REACT_APP_CDN_ENDPOINT;

  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("HomePageCardComponent")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Icon")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Name")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Title")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={2}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Size")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={3}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("SortOrder")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={4}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Status")}
                    </div>
                  </SortByItem>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              {!loading && data && data.length === 0 && (
                <tr>
                  <td colSpan="6">
                    <NoData />
                  </td>
                </tr>
              )}
              {loading && (
                <tr>
                  <td colSpan="6" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              )}
              {!loading &&
                data.map((item) => (
                  <tr key={item._id}>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-center inline-block">
                        <img
                          srcSet={cdnEndpoint + "/upload/img/" + item.image}
                          alt="No IMG"
                          className="min-w-[120px]"
                        />
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-slate-800 dark:text-slate-100 text-left">
                        <Link
                          className="text-sky-500 dark:text-sky-400"
                          to={"/cms/homePageCardComponent/edit/" + item._id}
                        >
                          {item.name}
                        </Link>
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-slate-800 dark:text-slate-100 text-center">
                        {item.title}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-slate-800 dark:text-slate-100 text-center">
                        {item.size}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="font-medium text-slate-800 dark:text-slate-100 text-center">
                        {item.order}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="flex items-center justify-center">
                        <div
                          className={classNames(
                            "flex font-medium rounded-full text-center px-2.5 py-0.5",
                            item.active
                              ? "bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400"
                              : "bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
                          )}
                        >
                          {item.active
                            ? ConfigProvider.getTranslation("Active")
                            : ConfigProvider.getTranslation("Passive")}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default HomePageCardComponents;

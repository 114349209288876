import React, { useState, useEffect } from "react";
import { usePrefences } from "../../context/PrefencesContext";
import { useConfig } from "../../context/ConfigContext";
import Alert from "../../components/Alert";
import { ArrowRightCircleIcon, CalendarIcon } from "@heroicons/react/24/solid";
import SortByItem from "../../components/SortByItems";
import Requests from "../../helpers/Requests";
import {
  addDays,
  checkRole,
  diagnosticResponse,
  formatDate,
  getDate,
} from "../../helpers/Helpers";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";

import DropdownFilter from "../../components/DropdownFilter";
import DropdownEvent from "../../components/Search/DropdownEvent";

import PaginationClassic from "../../components/PaginationClassic";
import { Link, useNavigate } from "react-router-dom";

function ReportEvent() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "ReportMatchReportsList")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  const [formData, setFormData] = useState({
    date1: formatDate(getDate(), true, false),
    date2: formatDate(addDays(getDate(), 7), true, false),
    eventSearch: null,
    sid: "50",
    page: 1,
    sort: 0,
    sortBy: 1,
    stat: "0",
    limitclass: null,
  });

  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = { ...formData };
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      Requests.postData("/report/getEventsReport", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [
    formData.page,
    formData.sort,
    formData.sortBy,
    PrefencesProvider.prefences.offset,
  ]);

  const allStat = {
    "-1": ConfigProvider.getTranslation("All"),
    0: ConfigProvider.getTranslation("Pending"),
    1: ConfigProvider.getTranslation("Started"),
  };
  const [allSports, setAllSports] = useState({});
  useEffect(() => {
    if (
      ConfigProvider.config.user.auth === "0" &&
      Object.keys(allSports).length === 0
    ) {
      getSports();
    }
    // eslint-disable-next-line
  }, []);
  const getSports = () => {
    Requests.postData("/sports/getAllSports", {})
      .then((response) => {
        let res = response.data;
        if (diagnosticResponse(res) && res.type === "success") {
          setAllSports(res.data);
        }
      })
      .catch(() => {});
  };
  const [allLimitClasses, setAllLimitClasses] = useState({});
  useEffect(() => {
    if (
      checkRole(
        ConfigProvider.config.user,
        "ReportMatchReportsListLimitClass"
      ) &&
      Object.keys(allLimitClasses).length === 0
    ) {
      getLimitClasses();
    }
    // eslint-disable-next-line
  }, []);
  const getLimitClasses = () => {
    Requests.postData("/sports/getAllLimitClasses", {})
      .then((response) => {
        let res = response.data;
        if (diagnosticResponse(res) && res.type === "success") {
          setAllLimitClasses(res.data);
        }
      })
      .catch(() => {});
  };
  return ConfigProvider.config.user !== null ? (
    <>
      <div className="flex space-x-4">
        <div className="relative grow min-w-0">
          {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (formData.page !== 1) {
                setFormData((prev) => {
                  return { ...prev, page: 1 };
                });
              } else {
                getItems();
              }
            }}
            className=""
          >
            {messages.length > 0 && (
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>
            )}
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                  {ConfigProvider.getTranslation("MatchReports")} ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                <button
                  type="submit"
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                  <span className="ml-2">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                </button>
              </div>
            </div>
            {/* More actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left side */}
              <div className="mb-4 grow flex flex-col lg:flex-row lg:mb-0 gap-4 mr-2">
                <DropdownEvent
                  setSelectedItem={(val) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        eventSearch: val,
                      };
                    });
                  }}
                />
              </div>
              {/* Right side */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-end gap-2">
                <input
                  id="date1"
                  name="date1"
                  type="datetime-local"
                  value={formData.date1}
                  onChange={(e) => {
                    return setFormData({ ...formData, date1: e.target.value });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("StartDate")}
                  disabled={loading}
                  required
                />
                <input
                  id="date2"
                  name="date2"
                  type="datetime-local"
                  value={formData.date2}
                  onChange={(e) => {
                    return setFormData({ ...formData, date2: e.target.value });
                  }}
                  className="w-full form-input"
                  placeholder={ConfigProvider.getTranslation("EndDate")}
                  disabled={loading}
                  required
                />
                <DropdownFilter align="right">
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("Status")}
                  </div>
                  <ul className="mb-4">
                    {Object.keys(allStat).map((item) => (
                      <li key={item} className="py-1 px-3">
                        <label className="flex items-center cursor-pointer">
                          <input
                            id={item}
                            name={item}
                            type="checkbox"
                            className="form-checkbox"
                            checked={formData.stat === item}
                            onChange={() =>
                              setFormData({
                                ...formData,
                                stat: item,
                              })
                            }
                            disabled={loading}
                          />
                          <span className="text-sm font-medium ml-2">
                            {allStat[item]}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                  <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                    {ConfigProvider.getTranslation("Sport")}
                  </div>
                  <ul className="mb-4">
                    {Object.keys(allSports).map((item) => (
                      <li key={item} className="py-1 px-3">
                        <label className="flex items-center cursor-pointer">
                          <input
                            id={item}
                            name={item}
                            type="checkbox"
                            className="form-checkbox"
                            checked={formData.sid === item}
                            onChange={() => {
                              setFormData({
                                ...formData,
                                sid: formData.sid === item ? null : item,
                              });
                            }}
                            disabled={loading}
                          />
                          <span className="text-sm font-medium ml-2">
                            {allSports[item]}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                  {checkRole(
                    ConfigProvider.config.user,
                    "ReportMatchReportsListLimitClass"
                  ) && (
                    <>
                      <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-3">
                        {ConfigProvider.getTranslation("LimitClasses")}
                      </div>
                      <ul className="mb-4">
                        {Object.keys(allLimitClasses).map((item) => (
                          <li key={item} className="py-1 px-3">
                            <label className="flex items-center cursor-pointer">
                              <input
                                id={item}
                                name={item}
                                type="checkbox"
                                className="form-checkbox"
                                checked={formData.limitclass === item}
                                onChange={() => {
                                  setFormData({
                                    ...formData,
                                    limitclass:
                                      formData.limitclass === item
                                        ? null
                                        : item,
                                  });
                                }}
                                disabled={loading}
                              />
                              <span className="text-sm font-medium ml-2">
                                {allLimitClasses[item]}
                              </span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </DropdownFilter>
              </div>
            </div>

            {/* Table */}
            <ReportEventTable
              data={data}
              loading={loading}
              formData={formData}
              setFormData={setFormData}
            />

            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                page={formData.page}
                setPage={(val) => {
                  setFormData((prev) => {
                    return { ...prev, page: val };
                  });
                }}
                totalItems={totalItems}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  ) : null;
}
function ReportEventTable({ data, loading, formData, setFormData }) {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("MatchReports")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th
                  colSpan="3"
                  className="border-b border-slate-200 dark:border-slate-700"
                ></th>
                <th
                  colSpan="2"
                  className="border-l border-b border-slate-200 dark:border-slate-700 px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap"
                >
                  <div className="text-center">
                    {ConfigProvider.getTranslation("Total")}
                  </div>
                </th>
                <th
                  colSpan="2"
                  className="border-l border-b border-slate-200 dark:border-slate-700 px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap"
                >
                  <div className="text-center">
                    {ConfigProvider.getTranslation("BetSlip.Single")}
                  </div>
                </th>
                <th
                  colSpan="2"
                  className="border-l border-b border-slate-200 dark:border-slate-700 px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap"
                >
                  <div className="text-center">
                    {ConfigProvider.getTranslation("BetSlip.Kombination")}
                  </div>
                </th>
              </tr>
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Competition")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Class")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Sport")}
                  </div>
                </th>

                <th className="border-l border-slate-200 dark:border-slate-700 px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={0}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Count")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Sum")}
                    </div>
                  </SortByItem>
                </th>
                <th className="border-l border-slate-200 dark:border-slate-700 px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={2}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Count")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={3}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Sum")}
                    </div>
                  </SortByItem>
                </th>
                <th className="border-l border-slate-200 dark:border-slate-700 px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={4}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Count")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <SortByItem
                    index={5}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Sum")}
                    </div>
                  </SortByItem>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              {!loading && data && data.length === 0 && (
                <tr>
                  <td colSpan="9">
                    <NoData />
                  </td>
                </tr>
              )}
              {loading ? (
                <tr>
                  <td colSpan="9" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              ) : (
                data.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-medium text-slate-800 dark:text-slate-100 flex flex-col">
                          <div className="flex items-center text-xs text-slate-700 dark:text-slate-500">
                            <CalendarIcon className="w-4 h-4 mr-1" />
                            {new Intl.DateTimeFormat("default", {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                              timeZone: PrefencesProvider.prefences.timezone,
                            }).format(new Date(item.eventdate))}
                          </div>
                          <div>
                            <Link
                              className="text-sky-500 dark:text-sky-400"
                              to={"/report/reportEvent/" + item.id}
                            >
                              {item.label}
                            </Link>
                          </div>

                          <div className="text-xs text-slate-700 dark:text-slate-500">
                            {`${item.catName}`}
                          </div>
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center justify-center w-full text-emerald-500">
                          {item.className}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center justify-center w-full text-purple-500">
                          {item.sport}
                        </div>
                      </td>

                      <td className="border-l border-slate-200 dark:border-slate-700 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center justify-center w-full text-xs opacity-50">
                          ({item.countTotal})
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center justify-center w-full">
                          {item.sumTotal}
                        </div>
                      </td>
                      <td className="border-l border-slate-200 dark:border-slate-700 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center justify-center w-full text-xs opacity-50">
                          ({item.countSingle})
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center justify-center w-full">
                          {item.sumSingle}
                        </div>
                      </td>
                      <td className="border-l border-slate-200 dark:border-slate-700 px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center justify-center w-full text-xs opacity-50">
                          ({item.countMultible})
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="flex items-center justify-center w-full">
                          {item.sumMultible}
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ReportEvent;
